import { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { BiSearch } from "react-icons/bi";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <HeaderWrapper className={`${menuOpen}`}>
      <button className={`menu ${menuOpen}`} onClick={toggleMenu}>
        <div className="">
          <span className="top"></span>
          <span className="bottom"></span>
        </div>
      </button>
      <ul>
        <li>
          <NavLink to="/">Home</NavLink>
        </li>
        <li>
          <NavLink to="/mission">Mission</NavLink>
        </li>
        <li>
          <NavLink to="/legions">Legions</NavLink>
        </li>
        <li>
          <NavLink to="/onchain">On Chain</NavLink>
        </li>
        <li>
          <NavLink to="/x0rquests">xor Quests/Games</NavLink>
        </li>
        <li>
          <NavLink to="/more">More</NavLink>
        </li>
        <li>
          <NavLink to="/faq">FAQ</NavLink>
        </li>
      </ul>
      <NavLink to="/search" className="search-btn">
        <BiSearch />
        <p>Search</p>
      </NavLink>
      <MobileMenu className={`${menuOpen}`}>
        <div className="links">
          <ul>
            <li>
              <NavLink to="/" onClick={toggleMenu}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to="/mission" onClick={toggleMenu}>
                Mission
              </NavLink>
            </li>
            <li>
              <NavLink to="/legions" onClick={toggleMenu}>
                Legions
              </NavLink>
            </li>
            <li>
              <NavLink to="/onchain" onClick={toggleMenu}>
                On Chain
              </NavLink>
            </li>
            <li>
              <NavLink to="/x0rquests" onClick={toggleMenu}>
                xor Games
              </NavLink>
            </li>
            <li>
              <NavLink to="/more" onClick={toggleMenu}>
                More
              </NavLink>
            </li>
            <li>
              <NavLink to="/faq" onClick={toggleMenu}>
                FAQ
              </NavLink>
            </li>
          </ul>
        </div>
      </MobileMenu>
    </HeaderWrapper>
  );
};

const MobileMenu = styled.div`
  z-index: 101;
  display: flex;
  overflow: hidden;
  background-color: #000;
  position: fixed;
  height: 100vh;
  transform: translatex(-100vw);
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  transition: transform 0.3s ease-in-out;
  justify-content: center;
  align-items: flex-start;
  .links {
    display: flex;
    width: 100%;
    margin-top: 7rem;

    ul {
      padding: 0;
      margin: 0 auto;
      list-style: none;
      align-items: center;
      display: flex;
      flex-direction: column;

      a {
        font-family: "Request", sans-serif;

        display: block;
        font-size: 0.9rem;
        margin: 16px 0;
        cursor: pointer;
        :hover {
          color: #c7ffcb;
          text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
        }

        &.active {
          color: #20c20e;
        }
      }
    }
  }
  &.true {
    transform: translatex(0);
  }
`;

const HeaderWrapper = styled.header`
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background-color: #00000080;
  color: rgb(177, 177, 177);
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    list-style: none;
    align-items: center;
    margin-right: 2rem;
    li {
      margin: 0.75rem 1rem;
      a {
        font-family: "Request", sans-serif;

        position: relative;
        display: block;
        font-size: 0.9rem;
        padding: 0 0 3px;
        transition: all 0.2s ease-in-out 0s;
        cursor: pointer;
        background-color: transparent;
        color: inherit;
        text-decoration: none;
        :hover {
          color: #c7ffcb;
          text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
        }

        &.active {
          color: #20c20e;
        }
      }
    }
  }
  button.menu {
    position: relative;
    padding: 0;
    display: none;
    align-items: center;
    justify-content: center;
    border-width: 0 !important;
    margin-left: 1.5rem;
    background: none;
    width: 1.5rem;
    height: 2rem;
    border-color: rgba(0, 0, 0, 0);
    appearance: none;
    outline: none;
    div {
      position: relative;
      width: 1.5rem;
      height: 12px;
      transition: 0.085s all ease-in;
      cursor: pointer;
      display: flex;
      span {
        position: absolute;
        height: 2px;
        width: 1.5rem;
        -webkit-transform-origin: left;
        -ms-transform-origin: left;
        transform-origin: left;
        display: block;
        transition: 0.085s all ease-in;
        border-radius: 100px;
        background-color: #c7ffcb;
        &.top {
          top: 0;
        }
        &.bottom {
          bottom: 0;
        }
      }
    }
    :hover {
      span.top {
        width: 1rem;
      }
    }
    &.true {
      div {
        width: 18px;
        height: 19px;
      }
      span.top {
        transform-origin: left center;
        width: 1.5rem;
        transform: rotate(45deg);
      }
      span.bottom {
        transform-origin: left center;
        transform: rotate(-45deg);
      }
    }
  }
  .search-btn {
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-right: 1rem;
    color: #b1b1b1;
    text-decoration: none;
    p {
      margin-left: 1rem;
      font-size: 1rem;
    }
    :hover {
      color: #c7ffcb;
      text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
    }
    &.active {
      display: none;
    }
  }
  @media (max-width: 800px) {
    padding: 0.5rem;
    justify-content: space-between;
    &.true {
      background-color: #000;
    }

    ul {
      display: none;
    }
    button.menu {
      display: flex;
      z-index: 999;
    }
    .search-btn {
      padding: 0 1rem;
      border-radius: 70px;
      background-color: #20c20e;
      color: #000;
      :hover {
        background-color: rgb(199, 255, 203 / 75%);
        text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
        box-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
      }
    }
  }
`;

export default Header;
