import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import Matrix from "../components/matrix";
import Accordion from "../components/Accordion";

const FAQ = () => {
  const faq = {
    title: "Frequently asked questions",
    text: "Answers for most popular questions",
    options: [
      {
        title: "Who is x0r?",
        text: "x0r is an alias for programmer, magician, and digital artist Michael Blau. x0r specializes in code-generated ASCII art and digital illusions. All x0r pieces are visual representations of Ethereum or Blockchain technologies. Each piece contains the actual source code behind blockchain primitives.",
      },
      {
        title: "What is XOR?",
        text: 'The XOR or "Exclusive Or" bitwise operation is a critical operation used in ciphers and the field of cryptography. For example, a fundamental cipher known as The One Time Pad uses XOR as an encryption method/function to encrypt a secret message. XOR plays a significant role in cryptography, and NFTs are essentially cryptographically verified ownership of digital assets.',
      },
      {
        title: "What is ASCII?",
        text: "ASCII stands for American Standard Code for Information Interchange. ASCII is a standard describing how a computer can represent letters with numbers. ASCII can encode 128 characters. ",
      },
      {
        title: "What is MEV?",
        text: (
          <span>
            MEV (Miner Extractable Value) is a fascinating economic phenomenon
            on the Ethereum Blockchain. MEV is the name for any value captured
            by miners who censor, reorder, or manipulate transactions in the
            blocks they produce. MEV bots will reorder transactions in Ethereum
            blocks to do things such as front-run your trade on Uniswap or
            mass-mint new NFTs! Because MEV bots often front-run pending
            transactions on the network, people refer to Ethereum as a dark
            forest where creatures hide in the shadows, ready to exploit your
            transactions before they are confirmed on-chain.{" "}
            <a
              href="https://www.paradigm.xyz/2020/08/ethereum-is-a-dark-forest/"
              target="_blank"
              className="accent_email"
              rel="noreferrer"
            >
              Here is a great example
            </a>
            <br />
            <br />
            The MEV Army visualizes MEV bots (or creatures, or shadowy super
            coders) lurking in the Ethereum dark forest. To learn more about
            different types of MEV bots or MEV strategies, please{" "}
            <Link to="/legions">read here</Link>.
          </span>
        ),
      },
      {
        title: "What are the traits in the MEV Army?",
        text: (
          <>
            <ul>
              <li>Helmet: 5 variations</li>
              <li>Miner Light: 8 variations</li>
              <li>Eyes: 16 variations</li>
              <li>Mouth: 12 variations</li>
              <li>Face: 9 variations</li>
              <li>Mouth: 12 variations</li>
              <li>Legion: 6 variations</li>
              <li>Binary: 2 variations</li>
            </ul>
            <br />
            <span>
              The legion trait and binary trait are "meta traits." This means
              that these traits are not visually represented in the art. Rather,
              they are simply traits that can be used in future MEV Army
              projects and events. Each legion has approximately 1,666 members.
              Also, 50% of the MEV Army have a binary trait of 1, and 50% have
              0. These traits can be primitives for developers and community
              members to build on top of the project.
              <br />
              You can learn more about trait rarities{" "}
              <a
                href="https://app.traitsniper.com/mev-army"
                target="_blank"
                className="accent_email"
                rel="noreferrer"
              >
                here
              </a>
              .
            </span>
          </>
        ),
      },
      {
        title: "What data is on-chain and what is off-chain?",
        text: "All MEV Army GIFs and metadata are stored on Arweave, a decentralized storage network (also known as the PermaWeb). The trait data for each MEV Army member will also be stored on-chain in a smart contract for developers to access and build projects with.",
      },
      {
        title: "MEV Army Bot Access?",
        text: "Some have asked whether the MEV Army community will get access to a Bot that can generate arbitrage profits. This is NOT currently planned. ",
      },
      {
        title:
          "What are a few inspirational ideas for what to build with on-chain data?",
        text: (
          <>
            <ol>
              <li>
                There is an equal amount of each legion (approx. 1666 each).
                These could be "teams" for an event, game, or scavenger hunt.
              </li>
              <li>
                The binary trait is 0 or 1 (50% of NFTs have 0 and 50% have 1).
                The binary feature could separate the MEV Army into two teams.
                It can also be used as an initial seed to create something
                generative.
              </li>
              <li>
                You can convert a series of 1s and 0s into numbers or letters.
                Each MEV Army NFT has a 1 or 0 attached to it. For example,
                decode this "01001101 01000101 01010110" using{" "}
                <a
                  href="https://www.binaryhexconverter.com/binary-to-ascii-text-converter"
                  target="_blank"
                  rel="noreferrer"
                >
                  this
                </a>
                .
              </li>
              <li>
                If you want to create more "unequal" teams or gated access, the
                light trait ranges from common to rare.{" "}
              </li>
              <li>
                Can certain lights see things (decrypt or decode things) others
                can't?
              </li>
              <li>
                If we are MEV army bots battling in the pending transaction pool
                of Ethereum...do we need weapons?
              </li>
              <li>
                What if MEV Army holders could claim specific NFTs based on what
                legion they are in?
              </li>
              <li>Does each legion need a flag or banner?</li>
            </ol>
          </>
        ),
      },
    ],
  };
  return (
    <Wrapper>
      <div className="container">
        <div className="text-section">
          <div className="title-text">
            <Fade bottom cascade duration={600}>
              <h1>FAQ</h1>
            </Fade>
          </div>
        </div>
      </div>
      <div className="container info">
        <Accordion
          options={faq.options}
          renderItem={({ option, key, onSelect, isSelected }) => (
            <Fade bottom duration={600} delay={key * 100} key={key}>
              <div className="card accordion" onClick={onSelect}>
                <div className="card_container">
                  <div className={`plus_minus ${isSelected}`} />
                  <div>
                    <p>{option.title}</p>
                    <Fade duration={600} collapse when={isSelected}>
                      <p className="answer">{option.text}</p>
                    </Fade>
                  </div>
                </div>
              </div>
            </Fade>
          )}
        />
      </div>
      <Matrix />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: #000;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 3rem;

  div.container {
    display: flex;
    margin: 0 auto;
    padding-top: 200px;
    z-index: 1;
    max-width: 1200px;
    div.text-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      h1 {
        font-size: 4.5rem;
        font-family: "Request", sans-serif;
      }
      .button-container {
        display: flex;
      }
    }
  }
  div.info {
    padding-top: 4rem;
    flex-direction: column;

    .accordion {
      cursor: pointer;
      padding: 25px 30px;
      margin-bottom: 20px;
      background-color: #00000080;
      border: 2px solid #57c15f;
      .card_container {
        display: flex;
        .plus_minus {
          position: relative;
          min-width: 18px;
          width: 18px;
          height: 18px;
          font-size: 24px;
          line-height: 1;
          margin-right: 30px;
          margin-top: 6px;
          &:before,
          &:after {
            content: "";
            position: absolute;
            background-color: #fff;
            transition: transform 0.25s ease-out;
          }
          /* Vertical line */
          &:before {
            top: 0;
            left: 50%;
            width: 2px;
            height: 100%;
            margin-left: -1px;
            border-radius: 1px;
          }
          /* horizontal line */
          &:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            margin-top: -1px;
            border-radius: 1px;
          }
          &.true {
            cursor: pointer;
            &:before {
              transform: rotate(90deg);
            }
            &:after {
              transform: rotate(180deg);
            }
          }
        }
        p {
          margin: 0px;
          font-style: inherit;
          font-variant: inherit;
          font-stretch: inherit;
          color: #20c20e;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.6;
          display: inline;
        }
        p.answer {
          padding: 16px 16px 0px 0px;
          margin: 0px;
          font-style: inherit;
          font-variant: inherit;
          font-stretch: inherit;
          font-size: 17px;
          font-weight: 400;
          line-height: 1.6;
          color: #fff;
          max-width: 75vw;
          display: inherit;
          .accent_email {
            color: #20c20e;
            text-decoration: underline;
          }
          a {
            color: #20c20e;
            text-decoration: underline;
          }
          ul {
            list-style-type: square;
          }
        }
      }
    }
  }

  @media (max-width: 1247px) {
    div.container {
      div.text-section {
        flex: 1;
        h1 {
          font-size: 4.25rem;
        }
      }
    }
    div.info {
      padding-top: 3rem;
    }
  }
  @media (max-width: 1011px) {
    div.container {
      flex-direction: column;
      div.text-section {
        flex: 1;
        h1 {
          font-size: 3.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
    div.container {
      padding-top: 6.5rem;
      div.text-section {
        margin-bottom: 3rem;
        h1 {
          font-size: 2.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
`;

export default FAQ;
