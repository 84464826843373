import React from "react";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import Matrix from "../components/matrix";
import TextBox from "../components/TextBox";
import { NavLink } from "react-router-dom";
const OnChain = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="text-section">
          <div className="title-text">
            <Fade bottom cascade duration={600}>
              <div className="">
                <h1>On-Chain</h1>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <div className="container info">
        <Fade bottom duration={600}>
          <TextBox title="On-Chain trait data">
            <p>
              The traits for each MEV Army NFT are stored on-chain. The MEV Army
              trait data is easily accessible and composable with any Ethereum
              smart contract to encourage the community to build on top of the
              MEV Army collection.
            </p>
            <div className="icon-div">
              <span className="x0r">⊕</span>
              <p>
                Trait Data Smart Contracts{" "}
                <a
                  href="https://github.com/Blauyourmind/mev-army-trait-data-contracts"
                  target="_blank"
                  rel="noreferrer"
                >
                  Github Repo
                </a>
              </p>
            </div>
            <div className="icon-div">
              <span className="x0r">⊕</span>
              <p>
                Official{" "}
                <a
                  href="https://michael-blau.gitbook.io/mev-army-trait-data-documentation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documentation
                </a>
              </p>
            </div>
            <div className="icon-div">
              <span className="x0r">⊕</span>
              <p>
                Quick Start{" "}
                <a
                  href="https://www.youtube.com/watch?v=-eAEBL5tFfI "
                  target="_blank"
                  rel="noreferrer"
                >
                  Video
                </a>
              </p>
            </div>
          </TextBox>
        </Fade>
      </div>
      <Matrix />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: #000;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 3rem;

  div.container {
    display: flex;
    margin: 0 auto;
    padding-top: 200px;
    z-index: 1;
    max-width: 1200px;
    div.text-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      h1 {
        font-size: 4.5rem;
        font-family: "Request", sans-serif;
      }
      .button-container {
        display: flex;
      }
    }
  }
  div.info {
    padding-top: 4rem;
    flex-direction: column;
  }
  div.icon-div {
    display: flex;
    align-items: center;
  }

  span.x0r {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  @media (max-width: 1247px) {
    div.container {
      div.text-section {
        flex: 1;
        h1 {
          font-size: 4.25rem;
        }
      }
    }
    div.info {
      padding-top: 3rem;
    }
  }
  @media (max-width: 1011px) {
    div.container {
      flex-direction: column;
      div.text-section {
        flex: 1;
        h1 {
          font-size: 3.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
    div.container {
      padding-top: 6.5rem;
      div.text-section {
        margin-bottom: 3rem;
        h1 {
          font-size: 2.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
`;

export default OnChain;
