import React from "react";
import styled, { keyframes } from "styled-components";
import { Fade } from "react-awesome-reveal";
import { images } from "../assets/images";
import Button from "../components/button";
import Matrix from "../components/matrix";
import TextBox from "../components/TextBox";

const Home = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="text-section">
          <Fade bottom cascade duration={600}>
            <div className="title-text">
              <h1>
                Welcome to <br />
                <span>MEV Army</span>
              </h1>
              <div className="button-container">
                <Button href="https://opensea.io/collection/mev-army">
                  OpenSea
                </Button>
              </div>
            </div>
          </Fade>
        </div>
        <div className="image-section">
          <Fade bottom duration={600}>
            <div className="soldiers">
              <div className="soldier1">
                <img alt="MEV Army 1" src={images.mev3} />
              </div>
              <div className="soldier2">
                <img alt="MEV Army 2" src={images.mev1} />
              </div>
              <div className="soldier3">
                <img alt="MEV Army 3" src={images.mev2} />
              </div>
            </div>
          </Fade>
        </div>
      </div>
      <div className="container info">
        <Fade bottom duration={600}>
          <TextBox title="About">
            <p>
              MEV Army is a collection of shadowy super coders lurking in the
              Ethereum dark forest, ready to exploit transactions. MEV, as
              initially defined by Phil Daian in{" "}
              <a href="https://arxiv.org/pdf/1904.05234.pdf" target="_blank">
                Flash Boys 2.0
              </a>
              , stands for Miner Extractable Value. MEV is additional value
              captured by miners who censor and reorder transactions in blocks
              they produce.
            </p>
            <p>
              Each member of the MEV Army is composed of the original
              transaction ordering code from{" "}
              <a
                href="https://github.com/ethereum/go-ethereum/blob/7f7877a02326ecace5c120ddced413880c272170/miner/worker.go#L907"
                target="_blank"
              >
                go-ethereum
              </a>
              . Miners are not the only 'players' who can capture MEV; they
              include transaction searchers, liquidators, sandwichers,
              backrunners, time-bandits, and generalized frontrunners are
              scattered throughout the dark forest. These represent some of the
              traits in the MEV Army Collection.
            </p>
            <p>Do you have what it takes to join their squad?</p>
          </TextBox>
          <TextBox title="Vision">
            <p>
              The MEV Army project aims to bring the brightest minds of the
              crypto community together. Newcomers and veterans alike have much
              to learn from each other; whether you're a developer or an
              enthusiast, the MEV Army community is meant to become an
              educational resource for all aspects of the web3 ecosystem.
            </p>
            <p>
              In the spirit of our open-source community, MEV Army trait data
              will live completely on-chain, which might encourage our members
              to create educational puzzles, games, challenges, and even
              derivative projects. While only 9,999 MEV Army members will ever
              be minted, the dark forest has no bounds…
            </p>
            <p>
              It's been an honor to work with the Manifold team on this project,
              who have consistently pushed the boundaries of what NFTs are
              capable of. Thank you to Madison Blau (my sister) for helping
              design the artwork. This is just the beginning.
            </p>
          </TextBox>
        </Fade>
      </div>
      <Matrix />
    </Wrapper>
  );
};

const bounce = keyframes`
0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
`;
const largeBounce = keyframes`
0%{
      transform: translatey(10px);
    }
    50% {
      transform: translatey(-30px);
    }
    100% {
      transform: translatey(10px);
    }
`;

const Wrapper = styled.main`
  background-color: #000;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 3rem;

  div.container {
    display: flex;
    margin: 0 auto;
    padding-top: 200px;
    z-index: 1;
    max-width: 1200px;
    div.text-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      h1 {
        font-size: 2.5rem;
        font-family: "Request", sans-serif;

        span {
          margin-top: 2rem;
          font-size: 4.5rem;
        }
      }
      .button-container {
        display: flex;
      }
    }
    div.image-section {
      flex: 1;

      div.soldiers {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        div {
          img {
            vertical-align: top;
            max-width: 100%;
            height: auto;
          }
        }

        .soldier1 {
          width: 300px;
          position: relative;
          z-index: 3;
          animation: ${bounce} 4s ease-in-out infinite;
        }
        .soldier2 {
          width: 400px;
          margin: 0 -200px 0 -190px;
          position: relative;
          z-index: 4;
          animation: ${largeBounce} 4s ease-in-out infinite;
        }
        .soldier3 {
          width: 290px;
          position: relative;
          z-index: 1;
          animation: ${bounce} 4s ease-in-out infinite;
        }
      }
    }
  }
  div.info {
    flex-direction: column;
  }

  @media (max-width: 1247px) {
    div.container {
      div.text-section {
        flex: 1;
        h1 {
          font-size: 2.25rem;
          span {
            margin-top: 2rem;
            font-size: 3.5rem;
          }
        }
        .button-container {
        }
      }
      div.image-section {
        flex: 1;
        div.soldiers {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          div {
            img {
              vertical-align: top;
              max-width: 100%;
              height: auto;
            }
          }

          .soldier1 {
            width: 270px;
          }
          .soldier3 {
            width: 250px;
          }
          .soldier2 {
            width: 330px;
            margin: 0 -175px 0 -175px;
          }
        }
      }
    }
    div.info {
    }
  }
  @media (max-width: 1011px) {
    div.container {
      flex-direction: column;
      div.text-section {
        flex: 1;
        h1 {
          font-size: 2.5rem;
          span {
            margin-top: 2rem;
            font-size: 4rem;
          }
        }
        .button-container {
        }
      }
      div.image-section {
        div.soldiers {
          div {
            img {
            }
          }

          .soldier1,
          .soldier3 {
          }
          .soldier2 {
          }
        }
      }
    }
    div.info {
      padding-top: 4rem;
    }
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
    div.container {
      div.text-section {
        margin-bottom: 3rem;
        h1 {
          font-size: 1.5rem;
          span {
            margin-top: 2rem;
            font-size: 2.8rem;
          }
        }
        .button-container {
          margin-top: 3rem;
        }
      }
      div.image-section {
        div.soldiers {
          div {
            img {
            }
          }

          .soldier1 {
            width: 300px;
          }
          .soldier3 {
            width: 280px;
          }
          .soldier2 {
            width: 360px;
            margin: 0 -150px 0 -150px;
          }
        }
      }
    }
    div.info {
      padding-top: 4rem;
    }
  }
`;

export default Home;
