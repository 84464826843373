import { useState } from "react";

import styled from "styled-components";

/**
 * Accordion is a component to rendering collapsed items.
 */
const Accordion = ({ options, renderItem, ...rest }) => {
  const [selected, setSelected] = useState(null);

  const handleChange = (key) => {
    if (key === selected) {
      return setSelected(null);
    }

    return setSelected(key);
  };

  return (
    <Wrapper {...rest}>
      {options.map((option, key) => (
        <li key={key}>
          {renderItem({
            option,
            key,
            onSelect: () => handleChange(key),
            isSelected: selected === key,
          })}
        </li>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  max-width: 700px;
  list-style: none;
  margin: 0 auto;
  padding: 0;

  li {
    button {
      p {
        padding: 0px;
        margin: 0px;
        font-style: inherit;
        font-variant: inherit;
        font-stretch: inherit;
        font-family: "Request", sans-serif;
        color: #c3c3c3;
        font-size: 17px;
        font-weight: 600;
        line-height: 1.6;
      }
    }
  }
`;

export default Accordion;
