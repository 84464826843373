import { useState } from "react";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import Matrix from "../components/matrix";
import { BiSearch } from "react-icons/bi";

const Search = () => {
  const [error, setError] = useState(false);
  const [input, setInput] = useState("");
  const [data, setData] = useState(false);

  const handleSearch = (token) => {
    if (!token) {
      return setError("*** Please enter a Token ID ***");
    }
    const options = { method: "GET" };
    fetch(
      `https://api.opensea.io/api/v1/asset/0x99cd66b3D67Cd65FbAfbD2fD49915002d4a2E0F2/${token}`,
      options
    )
      .then((response) => response.json())
      .then((response) => {
        setData({
          token: response.name,
          num_sales: response.num_sales,
          image: response.image_url,
          owner: response.owner.address,
          traits: response.traits,
          arweave: `https://arweave.net/E7rz7sKa1wWxKlMeFVrtZHgRjGMdDadDkT4QF2vDDkw/${token}`,
        });
        setError(null);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="search-box">
          <Fade bottom cascade duration={600}>
            <div>
              <h1>Find Your MEV</h1>
            </div>
            <div className="search-container">
              <div className="input">
                <input
                  name="TokenID"
                  value={input}
                  onChange={(text) => setInput(text.target.value)}
                  placeholder="Token ID"
                  autoFocus
                  maxLength={4}
                />
              </div>
              <div className="search-btn" onClick={() => handleSearch(input)}>
                <BiSearch />
              </div>
            </div>
            <div className="error">{error}</div>
          </Fade>
        </div>
        {data ? (
          <Fade bottom cascade duration={600}>
            <div className="data">
              <div className="text-data">
                <p>
                  Token ID: {data?.token} &nbsp; num_sales: {data?.num_sales}
                </p>
                <p>
                  Token URI:{" "}
                  <a href={data?.arweave} target="_blank">
                    {data?.arweave}
                  </a>
                </p>
                <p>Owner Address: {data?.owner}</p>
              </div>
              <div className="cols">
                <div className="traits">
                  <ul>
                    {data?.traits?.map((trait) => {
                      return (
                        <li>
                          {trait.trait_type}: <span>{trait.value}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="image">
                  <img
                    src={data?.image}
                    style={{
                      width: "100%",
                      height: "100%",
                      aspectRatio: 230 / 250,
                    }}
                    alt="MEV army Nft"
                  />
                </div>
              </div>
            </div>
          </Fade>
        ) : null}
      </div>
      <Matrix />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: #000;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 3rem;

  div.container {
      width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding-top: 200px;
    z-index: 1;
    max-width: 1200px;
    justify-content: center;
    .search-container {
      display: flex;
      align-items: center;
      .input {
          flex:1;
          max-width: 700px;
        border: 3px solid #20c20e;
        display: flex;
        align-items: center;
        height: 90px;
        padding-left: 2rem;
        background-color: #00000080;

        input {
            width: 100%;
            display: flex;
          font-family: "PT Mono", monospace;
          background: none;
          border: none;
          transition: all 0.2s ease-in-out 0s;
          appearance: none;
          outline: none !important;
          color: #20c20e;
          font-size: 3rem;
        }
      }
      .search-btn {
        margin-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px !important;
        min-width:90px;
        border: 3px solid #20c20e;
        background-color: #00000080;

        font-size: 2.5rem;
        cursor: pointer;

        :hover {
            border-color: #c7ffcb;
    color: #c7ffcb;
    box-shadow: 0 0 8px rgb(199, 255, 203 / 75%);
    text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
        }
      }
    }
    .error {
      display: flex;
      margin-top: 1rem;
      color: #20c20e;
      justify-content: center;
    }
    .text-data {
      display: flex;
      flex-direction: column;
      padding: 1rem 3rem;
      margin: 2rem 0;
      justify-content: center;
      color: #c7ffcb;
      border: 3px solid #c7ffcb;
      text-decoration: none;
      text-transform: uppercase;
      cursor: crosshair;
      background-color: #00000080;
      box-shadow: 0 0 8px rgb(199, 255, 203 / 75%);
      text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
      overflow-wrap: break-word;

      a {
          color: #c7ffcb
      }
    }
    .cols {
      display: flex;
      margin-top: 3rem;
      

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .traits {
        margin-right: 4rem;
        background-color: #00000080;
        border: 3px solid #c7ffcb;
        justify-content: center;

        box-shadow: 0 0 8px rgb(199, 255, 203 / 75%);
        ul {
          display: flex;
          height: 100%;
          flex-direction: column;
          align-items: center
          list-style: none;
          padding: 0;
          margin: 0;
          justify-content: space-around;
          li {
            display: flex;
            align-items: center;
            height:100%;
            padding: 1rem;
            border-bottom: 2px dotted #c7ffcbca;
            text-transform: capitalize;
            justify-content: space-between;
            font-size: 1.1rem;
            :last-child {
                border-bottom: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
  div.container{
      .search-container {
              .input {
        padding-left: .5rem;

                  input {
                    font-size: 1.5rem;
                  }
              }
          
      }
      .data {
width: 100%;
          .cols {
            flex-direction: column;
            align-items: center;
            .traits{
                width: 100%;
                margin-right: 0;
                margin-bottom: 2rem;
            }
            .image {
                width: 100%
            }
        
          }
      }
  }
   
   
        
  }
`;

export default Search;
