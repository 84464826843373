import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import Matrix from "../components/matrix";
import Accordion from "../components/Accordion";

const ListItem = ({ index, title, children }) => {
  return (
    <ListItemWrapper>
      <h3 className="underline underline-offset-1 text-[1rem]">{`${index}. ${title}`}</h3>
      {children}
    </ListItemWrapper>
  );
};

const TermsOfService = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="text-section">
          <div className="title-text">
            <Fade bottom cascade duration={600}>
              <h1>Terms of Service</h1>
            </Fade>
          </div>
        </div>
      </div>
      <div className="list-container">
        <ListItem index="1" title="Eligibility">
          <p>
            You must be at least 13 years of age to access the Services. If you
            are under 18 years of age (or the age of legal majority where you
            live), you may use our Services only under the supervision of a
            parent or legal guardian who agrees to be bound by these Terms. If
            you are a parent or legal guardian of a user under the age of 18 or
            the age of legal majority, you agree to be fully responsible for the
            acts or omissions of the user in relation to the Services.
          </p>
        </ListItem>
        <ListItem index="2" title="Modifications">
          <p>
            We may modify part or all of the Services without notice, and we may
            update these Terms at any time.
          </p>
        </ListItem>
        <ListItem index="3" title="Ownership">
          <p>
            Unless otherwise indicated in writing by us, the Site and Services,
            all content and other materials contained therein, including without
            limitation, our logos, and all designs, text, graphics, pictures,
            information, data, software, sound files, other files, and the
            selection and arrangement thereof (collectively “Site Content”) are
            the proprietary property of x0r and our affiliates, licensors, or
            users as applicable. The x0r logo and any x0r product or service
            names, logos, or slogans that may appear on the Services or
            somewhere else are property of x0r and may not be copied, imitated
            or used, in whole or in part, without our prior written permission.
            In addition, the look and feel of the Site Content, including
            without limitation, all page headers, custom graphics, button icons,
            and scripts constitute the service mark, trademark, or trade dress
            of x0r and may not be copied, imitated, or used, in whole or in
            part, without our prior written permission. The 3D layer files and
            the ability to mint 3D or voxel other versions of x0r Collectibles
            are all the intellectual property of x0r.
          </p>
          <p>
            None of the x0r Collectible content is meant to confer any
            additional benefit to the owner of the x0r Collectible. Neither x0r
            or any third party has any obligation to provide any additional
            benefit, and neither x0r or any third party will have any
            responsibility or liability for, arising out of, or relating to any
            additional benefits.{" "}
          </p>
        </ListItem>
        <ListItem index="4" title="X0r Collectibles">
          <p>
            You represent and warrant that any purchase of a x0r Collectible by
            you is solely for your personal collection, use and enjoyment, and
            not for speculative or investment purposes, for use as a substitute
            for currency or other medium of exchange.
          </p>
          <p>
            You acknowledge and understand that by purchasing a x0r Collectible
            you are not acquiring any equity or other ownership or
            profit-sharing interest in x0r, or any of its affiliates or any
            brand or other business venture.
          </p>
          <p>
            LEGISLATIVE OR REGULATORY CHANGES AT THE STATE, FEDERAL OR
            INTERNATIONAL LEVEL MAY ADVERSELY AFFECT THE USE, TRANSFER, OR
            EXCHANGE OF THE X0R COLLECTIBLES.
          </p>
        </ListItem>
        <ListItem
          index="5"
          title="X0r Collectibles Purchased Through Third Party Platforms"
        >
          <p>
            X0r Collectibles can only be purchased through third party platforms
            (for example, OpenSea) (the “Third Party Platform”). You will be
            required to create an account with the Third Party Platform and by
            doing so, you are agreeing to comply with their terms of service.
            X0r will have no liability to you or to any third party for any
            claims or damages that may arise as a result of any transactions or
            services hosted by these Third Party Platforms.{" "}
          </p>
        </ListItem>
        <ListItem index="6" title="X0r Collectibles Purchased through Site">
          <p>
            We do not offer a hosted digital wallet on the Services. You will be
            required to link a supported blockchain-based digital wallet
            (“Supported Wallet”) provided by supported third-party unhosted
            wallet providers (“Supported Wallet Provider”). By connecting your
            Supported Wallet and minting an NFT with our smart contract you have
            purchased a x0r Collectible. We will deliver any x0r Collectibles
            that you purchase from us directly to your Supported Wallet and you
            will be responsible for safekeeping the passwords and keys
            associated with your Supported Wallet. We will not be able to
            recover purchased x0r Collectibles for you in the event that you
            lose access to your Supported Wallet account or under any other
            circumstances.{" "}
          </p>
          <p>
            Each Supported Wallet Provider is a third-party company that offers
            unhosted digital wallet products and services. We are not in any way
            affiliated or partnered with Supported Wallet Providers. Your use of
            each Supported Wallet Provider’s products and services is subject to
            its applicable terms of service. We will not be liable to you for
            any loss that results from your use of a Supported Wallet Provider’s
            products and services, including Supported Wallets.
          </p>
          <p>
            All pricing and payment terms are as set forth on the Services, and
            any payment obligations you incur are binding at the time of
            purchase. All sales are final. Unless otherwise required by
            applicable law, we will not provide a refund on any purchase.
          </p>
        </ListItem>
        <ListItem index="7" title="Linking Your Supported Wallet ">
          <p>
            When you link your Supported Wallet, you understand and agree that
            you are solely responsible for maintaining the security of your
            Supported Wallet and your control over any Supported Wallet-related
            authentication credentials, private or public cryptocurrency keys,
            non-fungible tokens or cryptocurrencies that are stored in or are
            accessible through your Supported Wallet. Any unauthorized access to
            your Supported Wallet by third parties could result in the loss or
            theft of x0r Collectibles and/or funds held in your wallet and any
            associated wallets, including any linked financial information such
            as bank account(s) or credit card(s). We are not responsible for
            managing and maintaining the security of your Supported Wallet nor
            for any unauthorized access to or use of your Supported Wallet. If
            you notice any unauthorized or suspicious activity in your Supported
            Wallet that seems to be related to the Services, please notify us
            immediately.
          </p>
        </ListItem>
        <ListItem index="8" title="Payment">
          <p>
            Pricing for x0r Collectibles is variable. The x0r Collectibles
            called “MEV Legion Banners” themselves are zero (0) dollars, but
            purchasers are required to pay a transaction fee (“Gas Fees”) for
            every transaction that occurs. Gas Fees fund the network of
            computers that run the decentralized Ethereum network. The value of
            the Gas Fee changes, often unpredictably, and is entirely outside of
            the control of x0r. The purchaser acknowledges that under no
            circumstances will a contract, agreement, offer, sale, bid, or other
            transaction regarding x0r Collectibles be invalidated, revocable,
            retractable, or otherwise unenforceable on the basis that the Gas
            Fees were unknown, too high, or otherwise unacceptable to the
            purchase. The purchaser also acknowledges and agrees that Gas Fees
            are non-refundable under all circumstances.
          </p>
        </ListItem>
        <ListItem index="9" title="Terms of Sale">
          <p>
            By placing an order on the Site, you agree that you are submitting a
            binding offer to purchase a x0r Collectible. If you are the initial
            purchaser of the x0r Collectible then all amounts due are to be paid
            to x0r. If you are not the initial purchaser, then amounts are to be
            paid to the-then holder of the x0r Collectible. You also acknowledge
            and agree that x0r receives 0-10% of every subsequent sale of a x0r
            Collectible (“Royalty”) depending on the x0r Collectible. X0r has
            the right to collect Royalties for x0r Collectible sales in
            perpetuity and may use those funds in any manner x0r sees fit.
          </p>
          <p className="font-bold underline">
            As such, if you sell a x0r Collectible on a third-party NFT
            marketplace, you agree to include a statement substantially similar
            to the following in the description of the NFT:
          </p>
          <p className="italic">
            “0-10% Royalty Applies. See x0r Terms for details.”
          </p>
          <p>
            In addition, when you buy or sell a x0r Collectible on this Site,
            you agree to pay all applicable fees associated with the transaction
            and you authorize x0r to automatically charge and collect such fees
            from your payment. We will always display a breakdown of any
            transaction or other fees prior to your purchase or sale of a x0r
            Collectible.
          </p>
          <p>
            No refunds are permitted except with respect to any statutory
            warranties or guaranties that cannot be excluded or limited by law.
          </p>
        </ListItem>
        <ListItem index="10" title="Risks">
          <p>
            Please note the following risks in accessing, purchasing, selling or
            using x0r Collectibles: The price and liquidity of blockchain
            assets, including x0r Collectibles, are extremely volatile and may
            be subject to large fluctuations. Fluctuations in the price of other
            digital assets could materially and adversely affect x0r
            Collectibles, which may also be subject to significant price
            volatility. Legislative and regulatory changes or actions at the
            state, federal, or international level may adversely affect the use,
            transfer, exchange, and value of x0r Collectibles. X0r Collectibles
            are not legal tender and are not backed by any government.
            Transactions in x0r Collectibles may be irreversible, and,
            accordingly, losses due to fraudulent or accidental transactions may
            not be recoverable. Some transactions in x0r Collectibles shall be
            deemed to be made when recorded on a public ledger, which is not
            necessarily the date or time that you initiated the transaction. The
            value of x0r Collectibles may be derived from the continued
            willingness of market participants to exchange fiat currency or
            digital assets for x0r Collectibles, which may result in the
            potential for permanent and total loss of value of a particular x0r
            Collectible should the market for that x0r Collectible disappear.
          </p>
          <p>
            You agree and understand that you are solely responsible for
            determining the nature, potential value, suitability, and
            appropriateness of these risks for yourself, and that we do not give
            advice or recommendations regarding x0r Collectibles, including the
            suitability and appropriateness of, and investment strategies for,
            x0r Collectibles. You agree and understand that you access and use
            the Services at your own risk; however, these statements do not
            disclose all of the risks associated with x0r Collectibles and other
            digital assets. You agree and understand that we will not be
            responsible for any communication failures, disruptions, errors,
            distortions or delays you may experience when using x0r Collectibles
            however caused.
          </p>
        </ListItem>
        <ListItem index="11" title="No Guarantees or Future Promises">
          <p>
            X0r hopes to put out more content and create things in the future.
            When you purchase your x0r Collectible you agree that your purchase
            from our launch is all you are guaranteed to receive in exchange for
            your funds. Whether through primary or secondary channels, the art
            is what you receive. Any future benefits are ancillary to this
            purchase and not to be taken into consideration with your initial
            purchase. You agree that you are not relying on any future
            commitments by x0r.{" "}
          </p>
        </ListItem>
        <ListItem
          index="12"
          title="X0r Collectibles Are Not Intended as Investments"
        >
          <p>
            X0r Collectibles are meant to be a NFT for you to collect. They are
            not meant as investment vehicles. We make absolutely no promise or
            guarantee that these NFT’s will be worth anything more than what you
            and the market deem the art to be worth. This could very well be
            zero. X0r Collectibles have no inherent monetary value, and should
            be treated as nothing more than a collectible
          </p>
        </ListItem>
        <ListItem index="13" title="Taxes">
          <p>
            You are entirely responsible for any tax liability which may arise
            from minting or reselling your x0r Collectible.
          </p>
        </ListItem>
        <ListItem index="14" title="Disclaimers">
          <p>
            THE SERVICES AND ANY SITE CONTENT CONTAINED THEREIN, AND ANY AND ALL
            X0R COLLECTIBLES LISTED THEREIN ARE PROVIDED ON AN “AS IS” AND “AS
            AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
            EITHER EXPRESS OR IMPLIED. X0R (AND ITS SUPPLIERS) MAKE NO WARRANTY
            THAT THE SERVICES AND ANY X0R COLLECTIBLES WILL (1) MEET YOUR
            REQUIREMENTS; (2) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE,
            OR ERROR-FREE BASIS; OR (3) BE ACCURATE, RELIABLE, COMPLETE, LEGAL,
            OR SAFE.
          </p>
          <p>
            WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN
            OR TAKEN IN RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON THE
            SERVICES. X0R DOES NOT REPRESENT OR WARRANT THAT SITE CONTENT IS
            ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE.
          </p>
          <p>
            WHILE WE ATTEMPT TO MAKE YOUR ACCESS TO AND USE OF THE SERVICES AND
            SITE CONTENT SAFE, WE CANNOT AND DO NOT REPRESENT OR WARRANT THAT
            THE SERVICES, SITE CONTENT, ANY X0R COLLECTIBLES LISTED ON OUR
            SERVICES OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU
            DISCLOSE ONLINE. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
            INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD
            US RESPONSIBLE FOR ANY BREACH OF SECURITY.
          </p>
          <p>
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO
            RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF
            X0R COLLECTIBLES INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR
            CLAIMS ARISING FROM: (A) USER ERROR SUCH AS FORGOTTEN PASSWORDS,
            INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES;
            (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES;
            (D) UNAUTHORIZED ACCESS TO APPLICATIONS; AND/OR (E) ANY UNAUTHORIZED
            THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF
            VIRUSES, PHISHING, BRUTE FORCING OR OTHER MEANS OF ATTACK AGAINST
            THE SERVICES OR X0R COLLECTIBLES.
          </p>
          <p>
            X0R COLLECTIBLES ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY
            VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM NETWORK.
            ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET
            OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE ETHEREUM PLATFORM. WE
            DO NOT GUARANTEE THAT X0R CAN AFFECT THE TRANSFER OF TITLE OR RIGHT
            IN ANY X0R COLLECTIBLES.
          </p>
          <p>
            We are not responsible for sustained casualties due to vulnerability
            or any kind of failure, abnormal behavior of software (e.g., wallet,
            smart contract), blockchains or any other features of the x0r
            Collectibles. X0r is not responsible for casualties due to late
            report by developers or representatives (or no report at all) of any
            issues with the blockchain supporting x0r Collectibles including
            forks, technical node issues or any other issues having fund losses
            as a result.
          </p>
          <p>
            The x0r Collectibles are transferable blockchain-based non-fungible
            tokens that evidence a limited license to x0r Collectibles, and, in
            some cases, a limited license to third-party content and/or to
            access additional benefits, that is exclusive to the owner and
            thereby collectible as a form of alienable digital property. Unlike
            cryptocurrencies and other fungible digital assets, non-fungible
            tokens are not used to make payments. Non-fungible tokens are not
            mutually interchangeable and cannot be divided into smaller parts.
            These design features limit the usefulness of non-fungible tokens as
            a form of payment or substitute for currency. Instead, non-fungible
            tokens are enjoyed as digital collectibles
          </p>
          <p>
            Nothing in these Terms shall exclude or limit liability of either
            party for fraud, death or bodily injury caused by negligence,
            violation of laws, or any other activity that cannot be limited or
            excluded by legitimate means. SOME JURISDICTIONS DO NOT ALLOW THE
            EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO THE
            ABOVE EXCLUSION MAY NOT APPLY TO YOU.
          </p>
        </ListItem>
        <ListItem index="15" title="Limitation of Liability">
          <p>
            TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL X0R BE
            LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY
            INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
            DAMAGES ARISING FROM THESE TERMS, THE SERVICES, SITE CONTENT, X0R
            COLLECTIBLES, OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES
            RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
            ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA,
            WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
            OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF X0R HAS BEEN ADVISED
            OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE
            SERVICES, SITE CONTENT, OR THIRD PARTY SITES AND PRODUCTS ARE AT
            YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR
            ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA
            RESULTING THEREFROM.
          </p>
          <p>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO
            EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF X0R ARISING OUT OF OR
            IN ANY WAY RELATED TO THIS AGREEMENT, THE ACCESS TO AND USE OF THE
            SERVICES, SITE CONTENT, AND/OR X0R COLLECTIBLE EXCEED THE GREATER OF
            (A) $100 OR (B) THE AMOUNT YOU HAVE PAID TO X0R FOR THE SERVICES IN
            THE LAST TWELVE MONTHS OUT OF WHICH LIABILITY AROSE.
          </p>
        </ListItem>
        <ListItem index="16" title="Indemnification">
          <p>
            To the fullest extent permitted by applicable law, you will
            indemnify, defend and hold harmless x0r and our subsidiaries and
            affiliates, and each of our respective officers, directors, agents,
            partners and employees from and against any third party losses,
            liabilities, claims, demands, damages, expenses or costs arising out
            of or related to your access to or use of our Services{" "}
          </p>
        </ListItem>
        <ListItem index="17" title="Dispute Resolution and Arbitration">
          <p>
            Carefully read the following arbitration agreement ("Arbitration
            Agreement"). It requires you to arbitrate disputes with x0r and
            limits the manner in which you can seek relief from us.
          </p>
          <p>
            <span className="italic">
              Applicability of Arbitration Agreement.{" "}
            </span>
            You agree that any dispute or claim relating in any way to your
            access or use of the Site, to any Services sold or distributed
            through the Site, including x0r Collectibles, or to any aspect of
            your relationship with x0r will be resolved by binding arbitration,
            rather than in court, except that (1) you may assert claims in small
            claims court if your claims qualify; and (2) you or x0r may seek
            equitable relief in court for infringement or other misuse of
            intellectual property rights (such as trademarks, trade dress,
            domain names, trade secrets, copyrights, and patents).
          </p>
          <p>
            <span className="italic">Arbitration Rules and Forum.</span>
            The Federal Arbitration Act governs the interpretation and
            enforcement of this Arbitration Agreement. To begin an arbitration
            proceeding, you must send a letter requesting arbitration and
            describing your claim to 9030 West Sahara Avenue, Suite 298 Las
            Vegas, Nevada 89117. The arbitration will be conducted by JAMS, an
            established alternative dispute resolution provider. Disputes
            involving claims and counterclaims under $250,000, not inclusive of
            attorneys’ fees and interest, shall be subject to JAMS’s most
            current version of the Streamlined Arbitration Rules and procedures
            available at 
            <span className="font-bold">
              http://www.jamsadr.com/rules-streamlined-arbitration/;
            </span>{" "}
            all other claims shall be subject to JAMS’s most current version of
            the Comprehensive Arbitration Rules and Procedures, available at 
            <span className="font-bold">
              http://www.jamsadr.com/rules-comprehensive-arbitration/.
            </span>
            JAMS’s rules are also available at jamsadr.com or by calling JAMS
            at <span className="font-bold">800-352-5267.</span> If JAMS is not
            available to arbitrate, the parties will select an alternative
            arbitral forum. If the arbitrator finds that you cannot afford to
            pay JAMS’s filing, administrative, hearing and/or other fees and
            cannot obtain a waiver from JAMS, x0r will pay them for you. You may
            choose to have the arbitration conducted by telephone or video
            conference or based on written submissions, or you may request to
            meet in-person for arbitration in Las Vegas, Nevada. You agree that
            any judgment on the award rendered by the arbitrator may be entered
            in any court of competent jurisdiction.
          </p>
          <p>
            <span className="italic">Authority of Arbitrator.</span> The
            arbitrator shall have exclusive authority to (a) determine the scope
            and enforceability of this Arbitration Agreement and (b) resolve any
            dispute related to the interpretation, applicability, enforceability
            or formation of this Arbitration Agreement including, but not
            limited to any claim that all or any part of this Arbitration
            Agreement is void or voidable. The arbitration will decide the
            rights and liabilities, if any, of you and x0r. The arbitration
            proceeding will not be consolidated with any other matters or joined
            with any other cases or parties. The arbitrator shall have the
            authority to award monetary damages and to grant any non-monetary
            remedy or relief available to an individual under applicable law,
            the arbitral forum’s rules, and the Agreement (including the
            Arbitration Agreement). The arbitrator shall issue a written award
            and statement of decision describing the essential findings and
            conclusions on which the award is based, including the calculation
            of any damages awarded. The arbitrator has the same authority to
            award relief on an individual basis that a judge in a court of law
            would have. The award of the arbitrator is final and binding upon
            you and us. And you agree that to the extent monetary or
            non-monetary remedy or relief is granted, such request for relief
            may be enforced as needed by any court of competent jurisdiction.
          </p>
          <p>
            <span className="italic">Waiver of Jury Trial.</span> YOU AND X0R
            HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT
            AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and x0r are
            instead electing that all claims and disputes shall be resolved by
            arbitration under this Arbitration Agreement, except as otherwise
            indicated in this Section 17 An arbitrator can award on an
            individual basis the same damages and relief as a court and must
            follow this Arbitration Agreement as a court would. However, there
            is no judge or jury in arbitration, and court review of an
            arbitration award is subject to very limited review.
          </p>
          <p>
            <span className="italic">
              Waiver of Class or Other Non-Individualized Relief.
            </span>{" "}
            ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
            AGREEMENT MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A OR
            COLLECTIVE CLASS BASIS, ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND
            CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR
            CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. If a decision
            is issued stating that applicable law precludes enforcement of any
            part of this subsection’s limitations as to a given claim for
            relief, then that claim must be severed from the arbitration and
            brought in the state or federal courts located in the State of
            Nevada. All other claims shall be arbitrated.
          </p>
          <p>
            <span className="italic">30-Day Right to Opt Out. </span>
            You have the right to opt out of the provisions of this Arbitration
            Agreement by sending written notice of your decision to opt out to
            the following address: 9030 West Sahara Avenue, Suite 298 Las Vegas,
            Nevada 89117, within 30 days after first becoming subject to this
            Arbitration Agreement. You may also submit your decision to
            mb@x0rlabs.io. Your notice must include your name and address, the
            cryptocurrency wallet address you used to transact on this Site (if
            you have one), and an unequivocal statement that you want to opt out
            of this Arbitration Agreement. If you opt out of this Arbitration
            Agreement, all other parts of this Agreement will continue to apply
            to you. Opting out of this Arbitration Agreement has no effect on
            any other arbitration agreements that you may currently have, or may
            enter in the future, with us.
          </p>
        </ListItem>
        <ListItem index="18" title="Release">
          <p>
            To the fullest extent permitted by applicable law, you release x0r
            and the other x0r parties from responsibility, liability, claims,
            demands and/or damages (actual and consequential) of every kind and
            nature, known and unknown (including, but not limited to, claims of
            negligence), arising out of or related to:
          </p>
          <ol type="a">
            <li>a. Disputes between users,</li>
            <li>b. Acts or omissions of third parties, or</li>
            <li>
              c. Anything else for which you have agreed that x0r will have no
              responsibility or liability pursuant to these Terms.{" "}
            </li>
          </ol>
          <p>
            If you are a consumer who resides in California, you hereby waive
            your rights under California Civil Code § 1542, which provides: “A
            general release does not extend to claims that the creditor or
            releasing party does not know or suspect to exist in his or her
            favor at the time of executing the release and that, if known by him
            or her, would have materially affected his or her settlement with
            the debtor or released party.”
          </p>
        </ListItem>
        <ListItem index="19" title="Modifying and Termination our Services ">
          <p>
            We reserve the right to modify our Services or to suspend or stop
            providing all or portions of our Services at any time. You also have
            the right to stop using our Services at any time. We are not
            responsible for any loss or harm related to your inability to access
            or use our Services.{" "}
          </p>
        </ListItem>
        <ListItem index="20" title="Governing Law">
          <p>
            These Terms, your rights and obligations, and all actions
            contemplated by, arising out of or related to these Terms shall be
            governed by the laws of the State of Nevada, as if these Terms are a
            contract wholly entered into and wholly performed within the State
            of Nevada. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES
            SHALL BE DEEMED TO HAVE OCCURRED IN THE STATE OF NEVADA AND BE
            SUBJECT TO THE INTERNAL LAWS OF THE STATE OF NEVADA WITHOUT REGARD
            TO ITS CONFLICTS OF LAWS PROVISIONS.
          </p>
        </ListItem>
        <ListItem index="21" title="Severability">
          <p>
            If any provision or part of a provision of these Terms is unlawful,
            void or unenforceable, that provision or part of the provision is
            deemed severable from these Terms and does not affect the validity
            and enforceability of any remaining provisions.
          </p>
        </ListItem>
      </div>

      <Matrix />
    </Wrapper>
  );
};

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  font-size: 0.9rem;
  padding: 0 2rem;
  h3 {
    text-decoration: underline;
    font-size: 1rem;
    font-family: "IBM Plex Mono", monospace;
    margin: 0;
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
  }
`;

const Wrapper = styled.main`
  background-color: #000;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 3rem;
  div.list-container {
    display: flex;
    flex-direction: column;
    color: #57c15f;
  }

  div.container {
    display: flex;
    margin: 0 auto;
    padding-top: 200px;
    z-index: 1;
    max-width: 1200px;
    background: "red";
    div.text-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      h1 {
        font-size: 2.5rem;
        font-family: "Request", sans-serif;
      }
      .button-container {
        display: flex;
      }
    }
  }
  div.info {
    padding-top: 4rem;
    flex-direction: column;

    .accordion {
      cursor: pointer;
      padding: 25px 30px;
      margin-bottom: 20px;
      background-color: #00000080;
      border: 2px solid #57c15f;
      .card_container {
        display: flex;
        .plus_minus {
          position: relative;
          min-width: 18px;
          width: 18px;
          height: 18px;
          font-size: 24px;
          line-height: 1;
          margin-right: 30px;
          margin-top: 6px;
          &:before,
          &:after {
            content: "";
            position: absolute;
            background-color: #fff;
            transition: transform 0.25s ease-out;
          }
          /* Vertical line */
          &:before {
            top: 0;
            left: 50%;
            width: 2px;
            height: 100%;
            margin-left: -1px;
            border-radius: 1px;
          }
          /* horizontal line */
          &:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            margin-top: -1px;
            border-radius: 1px;
          }
          &.true {
            cursor: pointer;
            &:before {
              transform: rotate(90deg);
            }
            &:after {
              transform: rotate(180deg);
            }
          }
        }
        p {
          margin: 0px;
          font-style: inherit;
          font-variant: inherit;
          font-stretch: inherit;
          color: #20c20e;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.6;
          display: inline;
        }
        p.answer {
          padding: 16px 16px 0px 0px;
          margin: 0px;
          font-style: inherit;
          font-variant: inherit;
          font-stretch: inherit;
          font-size: 17px;
          font-weight: 400;
          line-height: 1.6;
          color: #fff;
          max-width: 75vw;
          display: inherit;
          .accent_email {
            color: #20c20e;
            text-decoration: underline;
          }
          a {
            color: #20c20e;
            text-decoration: underline;
          }
          ul {
            list-style-type: square;
          }
        }
      }
    }
  }

  @media (max-width: 1247px) {
    div.container {
      div.text-section {
        flex: 1;
        h1 {
          font-size: 2rem;
        }
      }
    }
    div.info {
      padding-top: 3rem;
    }
  }
  @media (max-width: 1011px) {
    div.container {
      flex-direction: column;
      div.text-section {
        flex: 1;
        h1 {
          font-size: 1.7rem;
        }
      }
    }
    div.info {
      padding-top: 1.5rem;
    }
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
    div.container {
      padding-top: 4.5rem;
      div.text-section {
        margin-bottom: 2.4rem;
        h1 {
          text-align: center;
          font-size: 1.5rem;
        }
      }
    }
    div.info {
      padding-top: 1.5rem;
    }
  }
`;

export default TermsOfService;
