import React from "react";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import Matrix from "../components/matrix";
import TextBox from "../components/TextBox";
import { NavLink } from "react-router-dom";
const Mission = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="text-section">
          <div className="title-text">
            <Fade bottom cascade duration={600}>
              <h1>Mission</h1>
            </Fade>
          </div>
        </div>
      </div>
      <div className="container info">
        <Fade bottom duration={600}>
          <TextBox
            title="What does it mean to be part of the MEV Army Community?"
            smallerTitle={true}
          >
            <p>
              The MEV Army project aims to bring the brightest minds of the
              crypto community together. Newcomers and veterans alike have much
              to learn from each other; whether you're a developer or an
              enthusiast, the MEV Army community is meant to become an
              educational resource for all aspects of the web3 ecosystem.
            </p>
            <p>
              In the spirit of our open-source community, MEV Army trait data
              will live completely on-chain, in hopes to encourage our members
              to create educational puzzles, challenges, and even derivative
              projects.{" "}
            </p>
            <p>
              In the past, x0r has created interactive educational events,
              challenges, and puzzles to teach collectors about fundamental
              blockchain concepts. The educational mediums have ranged from
              live-streams to on-chain scavenger hunts with the chance to win a
              1/1 NFT. x0r always enjoys thinking of diverse ways to help the
              community grow.
            </p>

            <p>
              x0r previously held an{" "}
              <a
                href="https://www.x0rart.com/for-those-who-dont-know-collection"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                on-chain scavenger hunt
              </a>{" "}
              with these{" "}
              <a
                href="https://gist.github.com/Blauyourmind/e73e6cd476631c5dd47f2699638a75e2"
                target="_blank"
                rel="noreferrer"
              >
                solutions
              </a>
              .{" "}
            </p>

            <p>
              x0r previously designed an{" "}
              <a
                href="https://www.x0rart.com/consensus"
                target="_blank"
                rel="noreferrer"
              >
                on-chain puzzle
              </a>{" "}
              with these{" "}
              <a
                href="https://gist.github.com/Blauyourmind/e91262e559abc5dfe61c50a0684a8525"
                target="_blank"
                rel="noreferrer"
              >
                solutions
              </a>
              .
            </p>

            <p>
              x0r's goal is to host many more of these events for the MEV Army
              community.
            </p>
          </TextBox>
          <TextBox title="What is the MEV Army Roadmap?">
            <p>
              Many projects establish roadmaps with limited flexibility, which
              might stifle creativity & innovation long-term. The MEV Army is
              about web3 education and building layers onto the initial
              collection (puzzles, challenges, scavenger hunts, educational
              tools, experimental NFT concepts, and much much more).
            </p>
            <p>
              In brief, The MEV Army "roadmap" will be a collaboration between
              my imagination and, of course, yours. To start, here are some
              experimental concepts:
            </p>
            <p>
              [1] An official MEV Army Fan Art NFT Collection. A community
              project where MEV Army holders can vote to mint the best fan art
              as official NFTs. See <a href='https://cc.x0rart.com' rel="noreferrer" target='_blank'>x0r Community Creations</a>.
            </p>
            <p>
              [2] A Smart Contract Investigation channel + live streams where we
              can explore the latest advancements in NFT smart contract
              development to understand what's happening under the hood; that
              way, everyone in our community has access to develop the skills
              necessary to understand the basis of NFT architecture (even if you
              don't know how to code). See  
              <a
                href="https://discord.gg/5qjgnxrW3F"
                target="_blank"
                rel="noreferrer"
              >#contract-investigation
              </a>  channel in Discord.{" "}
            </p>
            <p>
              [3] We are experimenting with mechanisms that will let MEV Army
              holders represent their ownership on other chains and test
              networks to participate in on-chain puzzles and scavenger hunts
              for zero or low costs without needing to move the MEV Army NFT
              itself. Many on-chain games inhibit participation due to high
              transaction costs on the Ethereum network. Community members
              should not need to pay large transaction fees to learn technical
              crypto concepts and participate. See <NavLink to="/Games">x0r Quests</NavLink>.
            </p>
            <p>
              [4] Educational tools to analyze smart contracts, such as a bot to
              identify where metadata is stored or a bot that can identify
              metamorphic contracts. See  
              <a
                href="https://discord.gg/5qjgnxrW3F"
                target="_blank"
                rel="noreferrer"
              >#tools</a>  channel in Discord.{" "}
            </p>
            <p>[5] Experimental NFTs. See <a href='https://mevbanners.x0rart.com' rel="noreferrer" target='_blank'>MEV Army Banners</a>.</p>
            <p>
              If you have a great idea for a project that can build on top of
              the MEV Army, put it in the{" "}
              <a
                href="https://discord.gg/5qjgnxrW3F"
                target="_blank"
                rel="noreferrer"
              >
                #mev-builders chat
              </a>
              , and we will make it a reality as a team. I placed a few more
              initial ideas in point 8 of the <NavLink to="/FAQ">FAQ</NavLink>.
              This is only the beginning. As I have said from the start, the
              dark forest has no bounds, and neither does this community.
            </p>
          </TextBox>

          <TextBox title="What are the 3 Pillars of the MEV Army Community?">
            <h4>Education</h4>
            <p>
              Education will have several components. To start, here are a few
              things that are going to happen:
            </p>
            <p>
              [1] x0r quests and workshops: We will hold ~45 minute workshops or live
              streams to discuss specific web3 / crypto concepts. See below for mor3 details on 
              x0r quests.
              <p>
                [2] Guest Speakers: We will bring in guest speakers and experts
                in the Discord or Twitter Spaces to teach our community about
                specific web3 concepts (for example, on-chain music with Arpeggi
                and MEV experts){" "}
              </p>
              <p>[3] Office Hours/Build Sessions: Periodically, we will host
              office hours or build sessions in the voice channel to answer any
              technical crypto questions.</p> 
            </p>

            <h4>Experimental NFTs</h4>
            <p>
              This pillar is, as it says, "experimental" and will be developed
              and extended over time as new ideas about NFT applications are
              generated. These are only the first few examples of things we are
              working on:{" "}
            </p>
            <p>
              [1] MEV Army trait data is fully on-chain, making this project
              composable and easily extendable. To inspire community builders, I
              am building the first project leveraging on-chain data to create
              MEV Army Twitter Banners for each MEV Legion. This will be a free
              claim/airdrop for all MEV Army holders.
            </p>
            <p>
              [2] I have been developing a UNIQUE concept in on-chain artwork
              that has not been explored in the NFT space, as far as I know.
              This NFT will be available on Nifty Gateway, and MEV Army holders
              will have a WL opportunity to mint this collectible NFT.
            </p>

            <h4>xor Quests/Games</h4>
            <p>
            We will periodically release guides, walkthroughs, puzzles, challenges, 
            or scavenger hunts on various crypto topics that the community can work 
            on to complete together. For example: Linking ENS Domains to websites 
            and NFT Sleep Minting. In the beginning, you will need to be a MEV 
            Army collector to participate. These "quests" will take place on 
            low-cost or free test networks (such as Rinkeby or Goerli). 
            We are experimenting with mechanisms to represent your MEV 
            Army ownership on different blockchains so you can participate 
            without worrying about risking your MEV, hacks, or expensive transaction 
            fees. This will create a fun and safe environment where people can 
            just focus on participating, experimenting, and learning.
            </p>
          </TextBox>
        </Fade>
      </div>
      <Matrix />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: #000;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 3rem;

  div.container {
    display: flex;
    margin: 0 auto;
    padding-top: 200px;
    z-index: 1;
    max-width: 1200px;
    div.text-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      h1 {
        font-size: 4.5rem;
        font-family: "Request", sans-serif;
      }
      .button-container {
        display: flex;
      }
    }
  }
  div.info {
    padding-top: 4rem;
    flex-direction: column;
  }

  @media (max-width: 1247px) {
    div.container {
      div.text-section {
        flex: 1;
        h1 {
          font-size: 4.25rem;
        }
      }
    }
    div.info {
      padding-top: 3rem;
    }
  }
  @media (max-width: 1011px) {
    div.container {
      flex-direction: column;
      div.text-section {
        flex: 1;
        h1 {
          font-size: 3.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
    div.container {
      padding-top: 6.5rem;
      div.text-section {
        margin-bottom: 3rem;
        h1 {
          font-size: 2.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
`;

export default Mission;
