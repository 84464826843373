import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./pages";
import Legions from "./pages/Legions";
import Mission from "./pages/Mission";
import FAQ from "./pages/FAQ";
import More from "./pages/More";
import Search from "./pages/Search";
import Terms from "./pages/TermsOfService";
import OnChain from "./pages/OnChain";
import Games from "./pages/Games";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AudioButton from "./components/AudioButton";
import ScrollToTop from "./components/ScrollToTop";
import { images } from "./assets/images";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>MEV Army by x0r</title>
        <meta
          name="description"
          content="MEV Army is a collection of shadowy super coders lurking in the Ethereum dark forest, ready to exploit transactions. MEV, as initially defined by Phil Daian in Flash Boys 2.0, stands for Miner Extractable Value. MEV is additional value captured by miners who censor and reorder transactions in blocks they produce."
        />
        <meta
          name="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/discovr-98d5c.appspot.com/o/images%2Fmevarmy.png?alt=media&token=3b486447-b2b8-4073-9f76-48c0015c519b"
        />
        <meta
          name="twitter:image"
          content="https://firebasestorage.googleapis.com/v0/b/discovr-98d5c.appspot.com/o/images%2Fmevarmy.png?alt=media&token=3b486447-b2b8-4073-9f76-48c0015c519b"
        />
        <link rel="icon" href={images.favicon} sizes="16x16" />
      </Helmet>
      <ScrollToTop>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/legions" element={<Legions />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/more" element={<More />} />
          <Route path="/onchain" element={<OnChain />} />
          <Route path="/x0rquests" element={<Games />} />
          <Route path="/search" element={<Search />} />
          <Route path="/terms-of-service" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </ScrollToTop>
      <AudioButton />
    </BrowserRouter>
  );
}

export default App;
