import React from "react";
import styled from "styled-components";

const TextBox = ({ title, icon, smallerTitle = false, children }) => {
  return (
    <TextBoxWrapper smallerTitle>
      <div className="top">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
          <h2>{title}</h2>
        </div>
        <div className="spacer" />
      </div>
      <div className="bottom">{children}</div>
    </TextBoxWrapper>
  );
};

const TextBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  margin-bottom: 4rem;
  justify-content: center;
  border: 3px solid #fff;
  transition: all 0.3s;
  font-family: "Request", sans-serif;
  color: #fff;
  letter-spacing: 4px;
  text-decoration: none;
  cursor: crosshair;
  background-color: #000000f3;

  :hover {
    border-color: #c7ffcb;
    color: #c7ffcb;
    box-shadow: 0 0 8px rgb(199, 255, 203 / 75%);
    text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);

    .top .spacer {
      background-color: #c7ffcb;
    }
  }
  .top {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: ${(props) => (props.smallerTitle ? ".8rem" : "1rem")};
    text-transform: uppercase;

    svg {
      height: 35px;
      width: 35px;
      margin-right: 1rem;
    }
    .spacer {
      height: 2px;
      background-color: #fff;
      width: 90%;
      margin-bottom: 1.5rem;
    }
  }
  .bottom {
    a {
      color: #20c20e;
      cursor: pointer;
      overflow-wrap: break-word;
    }
  }
  @media (max-width: 605px) {
    padding: 1.25rem 2rem;
  }
`;
export default TextBox;
