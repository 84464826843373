import React from "react";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import Matrix from "../components/matrix";
import TextBox from "../components/TextBox";
import { NavLink } from "react-router-dom";
const Games = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="text-section">
          <div className="title-text">
            <Fade bottom cascade duration={600}>
              <div className="">
                <h1>xor quests + games</h1>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <div className="container info">
        <Fade bottom duration={600}>
          <TextBox title="xor quests/games">
            <p>[0] Etherscan Workshops</p>
            <p>[1] Linking ENS to a website</p>
            <p>[2] NFT Sleep Minting</p>
            <p>[3] NFT Metadata Inspection</p>
            <p>[4] Legion Tokens Part 1</p>
            <p>[5] Forta Ethernaut Level 26</p>
            <p>[6] Legion Tokens Part 2</p>
            <p>[7] Community Quest by Tones: Hello, Legion! Part 1</p>
            <p>[8] Metamorphic Smart Contracts</p>
            <p>[9] Community Quest by Tones: Hello, Legion! Part 2</p>
            <p>[10] ERC-721 from Scratch with OpenZeppelin Wizard & Remix</p>
            <p>[11] NFT Royalties</p>
            <p>[12] Merkle Trees </p>
            <p>[13] Randomness Part 1 </p>
            <p>[14] Community Quest by Tones: Hello, Legion! Part 3 </p>
            <p>[15] Coming Soon... </p>
            <p>
              NOTE: Please see the Discord for more details on how to access
              previous x0r quests.
            </p>
          </TextBox>
        </Fade>
      </div>
      <Matrix />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: #000;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 3rem;

  div.container {
    display: flex;
    margin: 0 auto;
    padding-top: 200px;
    z-index: 1;
    max-width: 1200px;
    div.text-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      h1 {
        font-size: 4.5rem;
        font-family: "Request", sans-serif;
      }
      .button-container {
        display: flex;
      }
    }
  }
  div.info {
    padding-top: 4rem;
    flex-direction: column;
  }
  div.icon-div {
    display: flex;
    align-items: center;
  }

  span.x0r {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  @media (max-width: 1247px) {
    div.container {
      div.text-section {
        flex: 1;
        h1 {
          font-size: 4.25rem;
        }
      }
    }
    div.info {
      padding-top: 3rem;
    }
  }
  @media (max-width: 1011px) {
    div.container {
      flex-direction: column;
      div.text-section {
        flex: 1;
        h1 {
          font-size: 3.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
    div.container {
      padding-top: 6.5rem;
      div.text-section {
        margin-bottom: 3rem;
        h1 {
          font-size: 2.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
`;

export default Games;
