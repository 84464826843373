import styled from "styled-components";

const button = ({ href, children }) => {
  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        style={{
          textDecoration: "none",
        }}
      >
        <ButtonWrapper>
          <div>{children}</div>
        </ButtonWrapper>
      </a>
    );
  } else {
    return (
      <ButtonWrapper>
        <div>{children}</div>
      </ButtonWrapper>
    );
  }
};
const ButtonWrapper = styled.div`
  display: flex;
  padding: 0 28px;
  height: 68px;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
  transition: all 0.3s;
  font-family: "Request", sans-serif;
  color: #fff;
  letter-spacing: 4px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  :hover {
    border-color: #c7ffcb;
    color: #c7ffcb;
    box-shadow: 0 0 8px rgb(199, 255, 203 / 75%);
    text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
  }
`;

export default button;
