import { useEffect, useState } from "react";
import useInterval from "@use-it/interval";
var VALID_CHARS =
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%^&*()*&^%+-/~{[|`]}";

const MIN_LENGTH = 15;
const MAX_LENGTH = 50;

const getRandLength = (min, max) =>
  Math.floor(Math.random() * (max - min)) + min;
const getRandChar = () =>
  VALID_CHARS.charAt(Math.floor(Math.random() * VALID_CHARS.length));
const getRandStream = () =>
  new Array(getRandLength(MIN_LENGTH, MAX_LENGTH))
    .fill()
    .map((_) => getRandChar());

const RainStream = () => {
  const [stream, setStream] = useState(getRandStream());
  const [speed] = useState(Math.floor(Math.random() * 9 + 7));
  const [topPadding, setTopPadding] = useState(
    stream.length * -12 - Math.floor(Math.random() * 500)
  );

  useInterval(() => {
    if (topPadding > document.documentElement.offsetHeight) {
      setTopPadding(stream.length * -12);
    } else {
      setTopPadding(topPadding + speed);
      setStream((stream) => [...stream.slice(1, stream.length), getRandChar()]);
    }
  }, 100);
  return (
    <div
      style={{
        marginTop: topPadding,
        fontFamily: "PT Mono",
        color: "#20c20e",
        writingMode: "vertical-rl",
        textOrientation: "upright",
        whiteSpace: "nowrap",
        userSelect: "none",
        textShadow: "0px 0px 8px rgba(32, 194, 14 , .8)",
        fontSize: 12,
      }}
    >
      {stream.map((char, index) => (
        <a
          style={{
            color: index === stream.length - 1 ? "#fff" : undefined,
            opacity: index < 10 ? 0.1 + index * 0.15 : 1,
            textShadow:
              index === stream.length - 1
                ? "0px 0px 8px rgba(32, 194, 14 , .8)"
                : undefined,
            marginTop: -2,
          }}
        >
          {char}
        </a>
      ))}
    </div>
  );
};

const Matrix = () => {
  const streamCount = Math.floor(window.innerWidth / 50);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "space-between",
        zIndex: -1,
      }}
    >
      {new Array(streamCount).fill().map((_) => (
        <RainStream />
      ))}
    </div>
  );
};

export default Matrix;
