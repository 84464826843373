import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { MdPlayArrow, MdPause } from "react-icons/md";
import flux from "../assets/sounds/Flux.mp3";

const AudioButton = () => {
  const [audio] = useState(new Audio(flux));
  const [playing, setPlaying] = useState(false);

  const toggleMusic = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [playing]);
  return (
    <Wrapper onClick={toggleMusic}>
      <div className="bg" />
      <div className="toggle-btn">
        {playing ? <MdPause /> : <MdPlayArrow />}
      </div>
    </Wrapper>
  );
};
const pulse = keyframes`
0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
`;
const Wrapper = styled.div`
  cursor: pointer;
  .bg,
  .toggle-btn {
    z-index: 100;
    position: fixed;
    bottom: 30px;
    right: 45px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    color: #000;
    width: 70px;
    height: 70px;
    border-radius: 100%;
  }
  @media (max-width: 605px) {
    display: none !important;
  }

  .bg {
    animation: ${pulse} 1.2s ease infinite;
    background: rgba(32, 194, 14, 0.8);
  }
  .toggle-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #20c20e;
    background-size: 18px;
    cursor: pointer;
    outline: none;
    font-size: 30px;
  }
`;

export default AudioButton;
