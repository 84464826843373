import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import Matrix from "../components/matrix";
import Accordion from "../components/Accordion";

const ListItem = ({ index, title, children }) => {
  return (
    <ListItemWrapper>
      <h3 className="underline underline-offset-1 text-[1rem]">{`${index}. ${title}`}</h3>
      {children}
    </ListItemWrapper>
  );
};

const PrivacyPolicy = () => {
  return (
    <div>
        <div className="terms-outer">
            <header>
                <title>Privacy Policy | x0r Community Creations</title>
            </header>
            <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
            <div className="terms-inner">
                <p>Effective Date: December 15th, 2022</p>
                <p><strong>Thank you for using our Services. Your privacy is very important to us, and it is our intent to comply with any applicable law regarding the personal information we collect about you. </strong>This Privacy Policy explains our processing of the personal data collected through cc.x0rart.com (the &ldquo;<strong>Site</strong>&rdquo;) and any related mobile application(s) (together with the Site, the &ldquo;<strong>Services</strong>&rdquo;). By using the Services, you agree to this Privacy Policy. The Services are provided by x0r Labs, and we are a data controller. We are committed to protecting and respecting your privacy so you can navigate and use the Services safely.</p>
                <p>This Privacy Policy discloses what information we collect about you, how we gather it, how we use and share it, and what rights you have. This Privacy Policy may change from time to time, so we recommend that you read it periodically. This Privacy Policy is incorporated into our Terms which, together with this Privacy Policy, govern your use of the Services. We want to make this Privacy Policy as clear as possible. If, however, you have any questions, comments, or concerns about this Privacy Policy, please send us an email at privacy@x0rlabs.io.</p>
                <p>Some jurisdictions may provide for additional privacy rights and disclosures to their residents or relevant data subjects that may apply to you in certain circumstances. Otherwise, if you wish to exercise your rights and choices, please see the &ldquo;Your Choices and Rights&rdquo; section or if you have any questions about this Privacy Policy, contact us as set out in the &ldquo;Contact Us&rdquo; section below.</p>
                <p>As a user of our Services or a visitor to our Site, you must accept this Privacy Policy. If you do not consent to the terms of this Privacy Policy, do not access the Site or use the Services. By using the Services or accessing our Site, you agree to this Privacy Policy and to our processing of Personal Information as described in this Policy.</p>

                <p><strong>1.Information We Collect About You </strong></p>
                <p>Some of the information we collect about you identifies you. If it identifies you, we call it &ldquo;<strong>Personal Information</strong>.&rdquo; Personal Information does not include any information that is publicly available or information that has been anonymized to the point where a specific individual or household cannot be identified from the remaining information. If we associate non-identifiable information with information that identifies you, we will treat it as Personal Information.</p>
                <p>We only collect and use your Personal Information when we have a legitimate reason for doing so. We only collect Personal Information that is reasonably necessary to provide our Services to you and for the purposes set forth in this Policy. The information we collect can come from information you provide, information we collect through automated means, and information we collect from other sources, such as public sources.</p>
                <p><strong>Information You Provide. </strong>You provide Personal Information when you interact with us. For example, when you link your crypto wallet (&ldquo;<strong>Wallet</strong>&rdquo;) we may collect certain personal details about you. The Personal Information you provide may include the following:</p>
                <ul>
                    <li>Personal details, including your name, address, email address, phone number, and date of birth;</li>
                    <li>Transaction information, including payment details; and</li>
                    <li>Other Personal Information you choose to provide us when you interact with us.</li>
                </ul>

                <p>You are responsible for the accuracy of the Personal Information that you submit. We do not collect Personal Information unless you provide it to us. You can choose whether you provide Personal Information to us, but if you do not provide it to us, we may not be able to offer certain features of the Services.</p>
                <p><strong>Usage and Device Information. </strong>When you use our Services, we automatically collect certain browser or device-generated information by using various types of technology. This information includes, but is not limited to:</p>
                <ul>
                    <li>Your IP address or other device addresses or IDs;</li>
                    <li>Web browser and/or device type;</li>
                    <li>The pages or other content you view or interact with on the Services;</li>
                    <li>The server from which you are accessing our Services; and</li>
                    <li>The date, time, and place of accessing our Services.</li>
                </ul>

                <p>We may also use these technologies to collect information regarding your interaction with email messages, such as whether you have opened, clicked on, or forwarded a message.</p>
                <p><strong>Cookies and Other Tracking Technologies. </strong>Like most online services and mobile applications, we may use cookies and similar tracking technologies like web beacons, to record details of your activities through our Services (&ldquo;<strong>Usage Information</strong>&rdquo;). Usage Information does not identify you as an individual. Please see Section 7 for more information.</p>
                <p><strong>Analytics.</strong> We may work with different companies that offer services that can assist us in better understanding how users interact with and use the Services. For example, we may use a product called Google Analytics by permitting Google to use technologies such as web beacons and cookies to collect certain non-personally identifiable information that helps us understand your use of our Services.</p>
                <p><strong>Information from third parties</strong>. We may, from time to time, supplement the information we have about you with information from third parties for various purposes, including to enhance our ability to serve you, to offer you opportunities that may be of interest to you, and to improve the performance of our ads. We do not intentionally seek out any information about you from third-parties that you have not consented to providing or sharing.&nbsp;</p>
                <p><strong>Other information</strong>. When you contact customer support or communicate with us in any other way, we&rsquo;ll collect whatever information you volunteer or that we need to resolve your question. Note that our ability to resolve your question could be dependent upon you disclosing the information we require.</p>


                <p>2. <strong>How We Use This Information</strong>.</p>
                <p>We use your information to improve your experience and to deliver the Services you have requested.&nbsp;</p>
                <p>We use the information for our business purposes, which may include the following:&nbsp;</p>
                <ul>
                    <li>
                        <p>Planning and managing our activities, including better marketing of our Services, through researching and analyzing your use of the Services and sending you promotional information subject to your opt-out rights;</p>
                    </li>


                    <li>
                        <p>Customizing or personalizing your experience with our Services;</p>
                    </li>

                    <li>
                        <p>Providing you with requested customer support or other services;</p>
                    </li>

                    <li>
                        <p> Contacting and communicating with you;</p>
                    </li>

                    <li>
                        <p>Administering our Services, including processing your transactions and payments;</p>
                    </li>

                    <li>
                        <p>Monitoring aggregate usage metrics such as total number of visitors and tracking behavior on the Services;</p>
                    </li>
                    <li>
                        <p>Developing new features, functionalities, and services;</p>
                    </li>
                    <li>
                        <p>Operating, evaluating, maintaining, improving, or developing our Services and Site operation;</p>
                    </li>
                    <li>
                        <p>Protecting and ensuring safety of the Services, our confidential and proprietary information, and our employees;</p>
                    </li>
                    <li>
                        <p>Managing, protecting against and investigating fraud, risk exposure, claims and other liabilities, including but not limited to violation of the Terms of Service, laws or regulations;</p>
                    </li>
                    <li>
                        <p>For internal recordkeeping purposes;</p>
                    </li>
                    <li>
                        <p>Anonymizing your Personal Information for further internal and external uses in a manner that does not identify you; and</p>
                    </li>
                    <li>
                        <p>The ways we describe elsewhere in this Privacy Policy.</p>
                    </li>

                </ul>

                <p><strong>3. How we Share Your Information</strong></p>
                <p>Except as described elsewhere in this Privacy Policy and below, we do not disclose your Personal Information without your consent.</p>
                <p><strong>Related Parties and Affiliates. </strong>We may share Personal Information with a parent, subsidiary, or affiliate of our company.</p>
                <p><strong>Our Services</strong>. We may share Personal Information as necessary to provide our Services, complete a transaction, or fulfill your requests. We may share your information to monitor, improve, or better market our Services such as with third party cookie providers.</p>
                <p><strong>Service Providers and Professional Advisors</strong>. We may work with third party service providers to help us provide the Services and to assist us in operation, including, without limitation, in the areas of email management and deployment, analytics, marketing, advertising, market research, identity and professional credential validation, content distribution, customer service, payment fulfillment, even logistics, website maintenance and data storage and security, and we share your Personal Information so they can fulfill their obligations to us. We may also share your Personal Information with our professional advisors such as our legal representatives or insurance brokers.</p>
                <p><strong>Aggregate and Anonymous Information. </strong>We may make automatically-collected, aggregated, or otherwise non-personally-identifiable information available to third-parties for various purposes, including: (a)&nbsp;for compliance with various reporting obligations; (b)&nbsp;for business or marketing purposes; (c)&nbsp;to assist us in improving the products or Services; or (d)&nbsp;to assist such parties in understanding our users&rsquo; interests, habits, and usage patterns for certain content, services, advertisements, promotions, and/or functionality available through the Services.</p>
                <p><strong>Legal Requirements and Law Enforcement</strong>. We will disclose your Personal Information if we have a good faith belief that such disclosure is necessary to comply with valid legal requirements such as a law, regulation, search warrant, subpoena or court order and to meet national security or law enforcement requirements. We will also disclose your Personal Information to respond to a physical threat to you or others or to defend or assert legal rights of us or others. We may disclose your Personal Information to credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have provided to you.</p>
                <p><strong>Dispute Resolution</strong>. We will disclose your Personal Information as necessary to resolve disputes, inquiries or complaints. We may disclose your Personal Information if we believe you have violated our Terms of Service.</p>
                <p><strong>Sale of Personal Information for Marketing</strong><em>. </em>We do not pass on, sell, rent or lease any Personal Information to any third party for commercial or marketing purposes without your consent.</p>
                <p><strong>Business Transfers</strong>. We may also share your information in the event we are transferred or sold to another entity as a result of a corporate sale, merger, consolidation, asset sale, or in the unlikely event of bankruptcy.</p>
                <p><strong>Other Sharing.&nbsp;</strong>We reserve the right to disclose personal information that we believe, in good faith, is appropriate or necessary to: (a)&nbsp;take precautions against liability, (b)&nbsp;protect us or others from fraudulent, abusive, or unlawful uses or activity, (c)&nbsp;investigate and defend us against any third-party claims or allegations, (d)&nbsp;protect the security or integrity of the Services and any facilities or equipment used to make the Services available, or (e)&nbsp;protect our property or other legal rights, or the rights, property, or safety of others.</p>
                <p><strong>Your Consent</strong>. We may disclose your Personal Information to a third party in a manner not addressed by this Privacy Policy, subject to your consent at such time.</p>
                <p>We may share information that is not personally identifiable or is not otherwise subject to restrictions in this Policy.</p>
                <p>We expressly disclaim any and all liability for the actions of third parties. The privacy practices of any third parties are outside the scope of this Privacy Policy and their use or disclosure of your information (including your Personal Information) are governed by their own privacy practices.</p>
                <p>Other parties may not collect Personal Information about your online activities over time and across different Web sites when you use our Services.</p>


                <p><strong>4. Your Responsibilities. </strong></p>
                <p>You are responsible for verifying that your Personal Information is accurate and current. We are not responsible for the privacy or security of any information on the Services that you make publicly available or what others do with information you share with them. Please think carefully before deciding what information you share, including Personal Information, in connection with your use of the Services.</p>
                <p>Be aware that certain Personal Information and other information in connection with your use of the Services may be stored on your Device (even if we do not collect that information). You are solely responsible for maintaining the security of your Device from unauthorized access.</p>

                <p><strong>5. Your Choices and Rights.</strong></p>
                <p>You have choices when it comes to the privacy practices and communications described in this Privacy Policy. Many of your choices may be explained at the time you sign up for or use our Services or in the context of your use of our Services. You may be provided with instructions and prompts within the experiences as you navigate the Services. If you do not want your Personal Information used or shared as provided in this Privacy Policy, you should not use the Services.</p>
                <p>You may, of course, decline to share certain Personal Information with us in which case, we may not be able to provide some of the features and functionality of the Services.</p>
                <p><strong>Commercial Email Opt-Out</strong>. You can unsubscribe from email and other digital communications by following the instructions in the communication or by sending a request to us using the contact information listed below or through the Contact Us link provided at the bottom of every page of the Site.</p>
                <p>If you opt-out of receiving commercial emails from us or otherwise modify the nature or frequency of promotional communications you receive from us, it may take up to ten business days for us to process your request, and you may receive promotional communications from us that you have opted-out from during that period. Additionally, even after you opt-out from receiving commercial messages from us, you will continue to receive administrative messages from us regarding the Services.</p>
                <p><strong>Applicable Law. </strong>Subject to applicable law, you may have different rights regarding our use of your information. For example, you may have the following rights:</p>
                <ul>

                    <li>Right to request access to and to check what Personal Information we maintain about you;</li>
                    <li>Right to request copies of your Personal Information;</li>
                    <li>Right to update and correct inaccuracies in your Personal Information;</li>
                    <li>Right to have your Personal Information deleted;</li>
                    <li>Right to request restriction of the collection, processing, or use of information about you;</li>
                    <li>Right to request data portability;</li>
                    <li>Right to lodge a complaint with a supervisory authority; and</li>
                    <li>In some cases, the right to object to our processing of your information and to withdraw your consent to our processing of your information.</li>

                </ul>

                <p>When you request the deletion of your information, object or withdraw your consent to the processing of your information, we may not be able to provide all the features of the Services to you. You should be aware that it is not technologically possible to remove each and every record of the information you have provided to the Services from our servers.</p>
                <p>To exercise any of these rights please contact us using the contact information listed below or through the Contact Us link provided at the bottom of every page of the Site. We will promptly review all such requests in accordance with applicable laws.</p>
                <p><strong>International Users.</strong> Certain users may be considered &ldquo;data subjects&rdquo; for whom the processing of personal information is subjected to Regulation (EU) 2016/679 (&ldquo;<strong>GDPR</strong>&rdquo;) or the U.K. General Data Protection Regulation (&ldquo;<strong>UK GDPR</strong>&rdquo;) (each, a &ldquo;<strong>Covered Person</strong>&rdquo;).</p>
                <p>Covered Persons have the rights described in sections (1) through (4) below:</p>
                <p>1. <em>Right of access, rectification, erasure, and restriction of processing</em>: You may obtain access to your Personal Information stored by us. If we process or use your Personal Information, we shall endeavor to ensure, by implementing suitable measures, that your Personal Information is accurate and up to date for the purposes for which it was collected. If your Personal Information is inaccurate or incomplete, you may obtain the rectification of such Personal Information. You may obtain the erasure or restriction of processing of your Personal Information in situations where no legitimate business purpose exists, or the further storage is unnecessary.</p>
                <p>2. <em>Right to data portability</em>: You may receive the Personal Information that you have provided to us, in a structured, commonly-used, and machine-readable format or transmit this information to another controller.</p>
                <p>3. <em>Right to object</em>: You may object, on grounds relating to your particular situation, to the processing of your Personal Information. To the extent your Personal Information is processed for reasons other than direct marketing, you understand and agree that we have compelling legitimate reasons for information processing, such as providing access to our Services. You may use ad-blocking software to limit information capture and usage. Making a request does not guarantee that the request will be honored.</p>
                <p>4. <em>Right to withdraw consent</em>: If you consented to the collection or processing of your Personal Information, you may withdraw your consent without affecting the lawfulness of processing based on consent before its withdrawal.</p>
                <p>To exercise any of these rights, please contact us by email at <a href="mailto:privacy@x0rlabs.io">privacy@x0rlabs.io</a>.</p>
                <p>The legal bases for processing your Personal Information is our legitimate interests of providing the Services to you and the purposes described in this Privacy Policy.</p>


                <p><strong>6. Children&rsquo;s Privacy</strong></p>
                <p>The Services are designed and intended for use by adults and are not intended for nor designed to be used by children under the age of eighteen (18) years. We do not intend to collect Personal Information from any person we know is under the age of eighteen (18). We direct potential users under eighteen (18) years of age not to use the Services. If we learn that Personal Information of persons younger than eighteen (18) years of age has been collected, then we will take the appropriate steps to delete this information. To make such a request or if there are any questions or concerns about this Privacy Policy or its implementation, please contact us using the contact information listed below or through the Contact Us link provided at the bottom of every page of the Site.</p>

                <p><strong>7. Cookies and Other Tracking Technologies</strong></p>
                <p>&ldquo;Cookies&rdquo; are alphanumeric identifiers that are stored on a computer, browser, laptop, tablet, mobile phone, or other device (each, a &ldquo;<strong>Device</strong>&rdquo;), which collect information about your activity, browser, and Device. A &ldquo;web beacon&rdquo; (sometimes referred to as pixels and tags) is a small object or image that is embedded into a web page, application, or email, and is used to track activity.</p>
                <p>We may use both session (for the duration of your visit) and persistent (for the duration of a fixed period of time) cookies and tracking technologies. A cookie does not provide us with access to your computer or to Personal Information beyond what you provide to us. We do not store Personal Information in cookies.</p>
                <p>Some cookies are necessary to use the Services, and these cannot be disabled. Other cookies are not required to use our Services and may be disabled or deleted, but this may prevent you from properly using the Services. Most browsers allow you to adjust your cookie settings.</p>
                <p>We may use cookies to collect information about the areas within our Services that you view, your access times and frequency of your visits, your IP address, and the URL that has referred you to our Services. This helps us identify users, track behavior on the Services, keep the Services secure, and improve the Services&rsquo; performance. We may also use this data to help us understand our users and to shape our marketing.</p>

                <p><strong>8. How Your Information is Secured. </strong></p>
                <p>We follow generally accepted industry standards, including the use of appropriate administrative, physical, and technical safeguards, to protect the Personal Information submitted to us. Despite efforts to protect your Personal Information, no method of electronic transmission or storage is 100% secure, and there is always some risk that an unauthorized third party may find a way around our security systems or that transmissions of your information over the internet may be intercepted.</p>
                <p>If we learn of a security system breach, then we may attempt to notify you so that you can take appropriate protective steps. We may post a notice through the Services if a security breach occurs. Depending on where you live, you may have a legal right to receive notice of a security breach in writing, and we will follow all applicable laws.</p>

                <p><strong>9. How Long We Keep Your Personal Information </strong></p>
                <p>We will retain your Personal Information as long as needed to provide you Services. At any time, you can remove your Personal Information or instruct us to remove it, but you should be aware that it is not technologically possible to remove each and every record of the information you have provided to us from our servers. See &ldquo;Your Choices and Rights&rdquo; below for more information on how you can remove your Personal Information.</p>
                <p>Even though you may make changes or request deletion of your Personal Information, we may retain all information for backups, archiving, prevention of fraud and abuse, analytics, satisfaction of legal obligations, or where we otherwise reasonably believe that we have a legitimate reason to do so.</p>


                <p><strong>10. Note to Users Outside of the United States</strong></p>
                <p>Our Services are located and operated in the United States. The Personal Information that you provide to us is stored on servers located in the United States. If you are located in another jurisdiction, you should be aware that in order to provide the Services to you, we must transfer your Personal Information to the United States where it will be stored and processed in accordance with this Privacy Policy. We may transfer your information outside the United States to service providers with operations in other countries. By using the Services, you consent to such collection, storage and processing in the United States and elsewhere. The United States and other jurisdictions may not afford the same level of data protection as considered adequate in your own country. We take commercially reasonable steps to protect your Personal Information and to provide you with the same level of data protection.</p>
                <p>If you do not consent to such transfer, you may not use the Services. If you choose to access and use the Services from outside the United States, you acknowledge that you are doing so on your own initiative, at your own risk, and are responsible for compliance with applicable laws.</p>


                <p><strong>11. Changes to our Privacy Policy</strong></p>
                <p>We reserve the right to amend our Privacy Policy at any time and as needed. When we make changes to this Privacy Policy, we will post the updated Privacy Policy on our Site and update the Privacy Policy&rsquo;s effective date. Your continued use of any of the Services following the posting of changes constitutes your acceptance of such changes.</p>


                <p><strong>12. Contact Us </strong></p>
                <p>If you have any questions about the Services, or this Privacy Policy, please contact us at:</p>
                <ul>
                    <li>privacy@x0rlabs.io</li>
                    <li>The &ldquo;Contact&rdquo; section on our website www.x0rart.com/contact</li>
                    <li>9030 West Sahara Avenue, Suite 298 Las Vegas, Nevada 89117</li>
                </ul>
            </div>
        </div>
    </div>
);
};

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  font-size: 0.9rem;
  padding: 0 2rem;
  h3 {
    text-decoration: underline;
    font-size: 1rem;
    font-family: "IBM Plex Mono", monospace;
    margin: 0;
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
  }
`;

const Wrapper = styled.main`
  background-color: #000;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 3rem;
  div.list-container {
    display: flex;
    flex-direction: column;
    color: #57c15f;
  }

  div.container {
    display: flex;
    margin: 0 auto;
    padding-top: 200px;
    z-index: 1;
    max-width: 1200px;
    background: "red";
    div.text-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      h1 {
        font-size: 2.5rem;
        font-family: "Request", sans-serif;
      }
      .button-container {
        display: flex;
      }
    }
  }
  div.info {
    padding-top: 4rem;
    flex-direction: column;

    .accordion {
      cursor: pointer;
      padding: 25px 30px;
      margin-bottom: 20px;
      background-color: #00000080;
      border: 2px solid #57c15f;
      .card_container {
        display: flex;
        .plus_minus {
          position: relative;
          min-width: 18px;
          width: 18px;
          height: 18px;
          font-size: 24px;
          line-height: 1;
          margin-right: 30px;
          margin-top: 6px;
          &:before,
          &:after {
            content: "";
            position: absolute;
            background-color: #fff;
            transition: transform 0.25s ease-out;
          }
          /* Vertical line */
          &:before {
            top: 0;
            left: 50%;
            width: 2px;
            height: 100%;
            margin-left: -1px;
            border-radius: 1px;
          }
          /* horizontal line */
          &:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            margin-top: -1px;
            border-radius: 1px;
          }
          &.true {
            cursor: pointer;
            &:before {
              transform: rotate(90deg);
            }
            &:after {
              transform: rotate(180deg);
            }
          }
        }
        p {
          margin: 0px;
          font-style: inherit;
          font-variant: inherit;
          font-stretch: inherit;
          color: #20c20e;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 1.6;
          display: inline;
        }
        p.answer {
          padding: 16px 16px 0px 0px;
          margin: 0px;
          font-style: inherit;
          font-variant: inherit;
          font-stretch: inherit;
          font-size: 17px;
          font-weight: 400;
          line-height: 1.6;
          color: #fff;
          max-width: 75vw;
          display: inherit;
          .accent_email {
            color: #20c20e;
            text-decoration: underline;
          }
          a {
            color: #20c20e;
            text-decoration: underline;
          }
          ul {
            list-style-type: square;
          }
        }
      }
    }
  }

  @media (max-width: 1247px) {
    div.container {
      div.text-section {
        flex: 1;
        h1 {
          font-size: 2rem;
        }
      }
    }
    div.info {
      padding-top: 3rem;
    }
  }
  @media (max-width: 1011px) {
    div.container {
      flex-direction: column;
      div.text-section {
        flex: 1;
        h1 {
          font-size: 1.7rem;
        }
      }
    }
    div.info {
      padding-top: 1.5rem;
    }
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
    div.container {
      padding-top: 4.5rem;
      div.text-section {
        margin-bottom: 2.4rem;
        h1 {
          text-align: center;
          font-size: 1.5rem;
        }
      }
    }
    div.info {
      padding-top: 1.5rem;
    }
  }
`;

export default PrivacyPolicy;
