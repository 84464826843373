import React from "react";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import Matrix from "../components/matrix";
import TextBox from "../components/TextBox";
import {
  AiOutlineClockCircle,
  AiFillBackward,
  AiFillForward,
} from "react-icons/ai";
import { RiStackFill } from "react-icons/ri";
import { BsFillDropletFill } from "react-icons/bs";
import { MdRemoveRedEye } from "react-icons/md";

const Legions = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="text-section">
          <div className="title-text">
            <Fade bottom cascade duration={600}>
              <h1>Legions</h1>
            </Fade>
          </div>
        </div>
      </div>
      <div className="container info">
        <Fade bottom duration={600}>
          <TextBox title="Searcher" icon={<MdRemoveRedEye />}>
            <p>
              Searchers are bots that monitor the Ethereum pending transaction
              pool looking for arbitrage opportunities and constructing
              profitable transaction sequences. Searchers will send their
              transaction sequences directly to miners to (1) bypass the public
              transaction pool so nobody can see their strategy and (2) ensure
              miners execute the transaction sequence IN ORDER within a block.
              Searchers can use{" "}
              <a
                href="https://docs.flashbots.net/flashbots-auction/searchers/quick-start/"
                target="_blank"
              >
                Flashbots
              </a>{" "}
              to connect directly to miners.
            </p>
          </TextBox>
          <TextBox title="Generalized Frontrunner" icon={<AiFillForward />}>
            <p>
              Generalized Frontrunners seek to profit by frontrunning any
              arbitrary transaction, whether on a DEX or in a vulnerable smart
              contract. Generalized Frontrunner bots monitor the pending
              transaction pool, copy pending transaction data, and execute those
              transactions in a local testing environment as if they were the
              transaction sender. If the tested transaction results in a profit
              to the bot in any way, the bot will copy the transaction and send
              it to the blockchain for real with a higher gas price than the
              original transaction. Since miners prioritize transactions with
              higher gas prices, miners will most likely include the bot's
              transaction in a block before the original transaction.
              Generalized Frontrunners often lead to{" "}
              <a href="https://arxiv.org/pdf/1904.05234.pdf" target="_blank">
                Priority Gas Auctions
              </a>
              , where several bots bid using high gas prices to claim a
              profitable transaction.
            </p>
          </TextBox>
          <TextBox title="Liquidator" icon={<BsFillDropletFill />}>
            <p>
              Liquidators search for arbitrage opportunities in lending/debt
              protocols (i.e., Compound and Aave). Borrowers in these protocols
              usually need to over-collateralize their positions (i.e., post
              $100 worth of ETH as collateral to borrow $90 USDC). In periods of
              extreme volatility, the value of your ETH collateral may fall
              below your loan value, making your position under collateralized.
              Liquidators can now pay off your debt, seize your collateral at a
              discount, and immediately sell it for an instant risk-free profit.
              You can learn more about liquidations on Aave{" "}
              <a
                href="https://docs.aave.com/developers/guides/liquidations"
                target="_blank"
              >
                here
              </a>
              . Here is an{" "}
              <a
                href="https://etherscan.io/tx/0x27b899e6c56b259d4e95e8fe43cc0a17cdd519724aed6e2d8fa6a1269ad3e871"
                target="_blank"
              >
                example
              </a>{" "}
              of a real liquidation in the wild.
            </p>
          </TextBox>
          <TextBox title="Sandwicher" icon={<RiStackFill />}>
            <p>
              Sandwichers will monitor the pending transaction pool for large
              DEX trades (i.e., on Uniswap or Sushiswap) and construct
              transaction sequences that “sandwich” and frontrun a victim's
              large trade. For example, a sandwich bot would (1) buy an asset
              before the victim, pushing up the asset's price (2) execute the
              victim's large trade, causing the price to increase even more (3)
              immediately sell the asset at the new higher price. You can find a
              real example of a sandwich attack in this{" "}
              <a
                href="https://etherscan.io/txs?block=14014551&p=8"
                target="_blank"
              >
                block
              </a>
              , starting with this{" "}
              <a
                href="https://etherscan.io/tx/0x4125d4e7ce7794029a9340eee3fac0a62a4834f0dede5513fbd1b88995415bb6"
                target="_blank"
              >
                transaction
              </a>
              . Here is a great{" "}
              <a
                href="https://twitter.com/bertcmiller/status/1443531586406846470"
                target="_blank"
              >
                thread
              </a>{" "}
              for more details. Finally, there has been a recent example of a
              sandwich attack involving{" "}
              <a
                href="https://twitter.com/bertcmiller/status/1482086447775580161"
                target="_blank"
              >
                crypto punks
              </a>
              !
            </p>
          </TextBox>
          <TextBox title="Backrunner" icon={<AiFillBackward />}>
            <p>
              While Frontrunners try to get their transaction mined BEFORE some
              other target transaction, Backrunners use special techniques to
              get their transaction mined directly AFTER a target transaction.
              Target transactions may create profitable opportunities that did
              not exist before they were mined, so Backrunners capture these
              opportunities as soon as they become available. For example, a
              price oracle update transaction on Compound may cause several
              borrowers to become under collateralized. Backrunners will monitor
              these oracle updates and place liquidation transactions directly
              afterward, ensuring they are the first bot to capture the
              liquidation. There is an example of backrunning a Compound oracle
              update in this{" "}
              <a
                href="https://etherscan.io/txs?block=10626180&p=3"
                target="_blank"
              >
                block
              </a>
              , with this{" "}
              <a
                href="https://etherscan.io/tx/0xeab3bcaa5972bdc72a660204f5f921a0aa2e2e92737aa0de1278a32183f7392c"
                target="_blank"
              >
                liquidation transaction
              </a>{" "}
              occurring directly after this{" "}
              <a
                href="https://etherscan.io/tx/0x34b786e78c8b5f459a125f094c347e437af0595577d60db88ceec842242815b4"
                target="_blank"
              >
                price oracle update
              </a>
              . Finally, here is a{" "}
              <a
                href="https://www.youtube.com/watch?v=ApAJ1afUbBI"
                target="_blank"
              >
                video
              </a>{" "}
              walking through this strategy.
            </p>
          </TextBox>
          <TextBox title="Time-Bandit" icon={<AiOutlineClockCircle />}>
            <p>
              In a Time-Bandit attack, a miner would rewind the blockchain to
              capture profitable MEV opportunities that happened in a previous
              block. As far as we know, there has yet to be a Time-Bandit attack
              on Ethereum. However, there has been an{" "}
              <a
                href="https://twitter.com/bertcmiller/status/1382673587715342339?s=20"
                target="_blank"
              >
                Uncle-Bandit attack
              </a>
              , a very close cousin to the Time-Bandit. Time-Bandit attacks are
              a severe risk to the underlying security of Ethereum. You can
              learn more about Time-Bandit attacks{" "}
              <a href="https://arxiv.org/pdf/1904.05234.pdf" target="_blank">
                here
              </a>
              .
            </p>
          </TextBox>
        </Fade>
      </div>
      <Matrix />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background-color: #000;
  margin: 0;
  min-height: 100vh;
  color: #fff;
  position: relative;
  z-index: 1;
  padding: 0 3rem;

  div.container {
    display: flex;
    margin: 0 auto;
    padding-top: 200px;
    z-index: 1;
    max-width: 1200px;
    div.text-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      h1 {
        font-size: 4.5rem;
        font-family: "Request", sans-serif;
      }
      .button-container {
        display: flex;
      }
    }
  }
  div.info {
    padding-top: 4rem;
    flex-direction: column;
  }

  @media (max-width: 1247px) {
    div.container {
      div.text-section {
        flex: 1;
        h1 {
          font-size: 4.25rem;
        }
      }
    }
    div.info {
      padding-top: 3rem;
    }
  }
  @media (max-width: 1011px) {
    div.container {
      flex-direction: column;
      div.text-section {
        flex: 1;
        h1 {
          font-size: 3.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
  @media (max-width: 605px) {
    padding: 0 1rem;
    div.container {
      padding-top: 6.5rem;
      div.text-section {
        margin-bottom: 3rem;
        h1 {
          font-size: 2.5rem;
        }
      }
    }
    div.info {
      padding-top: 2rem;
    }
  }
`;

export default Legions;
