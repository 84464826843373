import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import { BsTwitter, BsFillFileCodeFill } from "react-icons/bs";
import { SiDiscord } from "react-icons/si";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterWrapper>
      <Fade bottom cascade duration={600} delay={500}>
        <div className="buttons">
          <a
            href="https://twitter.com/mevarmyofficial"
            className="button"
            target="_blank"
          >
            <BsTwitter />
          </a>
          <a href="https://t.co/ntJ0Us6nRb" className="button" target="_blank">
            <SiDiscord />
          </a>
          <a
            href="https://etherscan.io/address/0x99cd66b3D67Cd65FbAfbD2fD49915002d4a2E0F2"
            className="button"
            target="_blank"
          >
            <BsFillFileCodeFill />
          </a>
        </div>
      </Fade>
      <div className="bottom-links">
        <Link to="/terms-of-service">
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:underline underline-offset-1"
          >
            Terms of Service
          </a>
        </Link>
        <Link to="/privacy-policy">
          <a
            target="_blank"
            rel="noreferrer"
            className="hover:underline underline-offset-1"
          >
            Privacy Policy
          </a>
        </Link>
      </div>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 6rem;
  margin-top: 3rem;
  border-top: 3px solid #20c20e;
  .buttons {
    display: flex;
    a.button {
      color: #20c20e;
      margin: 0 1rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      :hover {
        color: #c7ffcb;
        box-shadow: 0 0 8px rgb(199, 255, 203 / 75%);
        text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
      }

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
  .bottom-links {
    margin-top: 7px;
    display: flex;
    font-size: 0.8rem;
    a {
      margin: 0 0.5rem;
      text-decoration: none;
      color: #20c20e;
      :hover {
        box-shadow: 0 0 8px rgb(199, 255, 203 / 75%);
        text-shadow: 0 0 10px rgb(199, 255, 203 / 75%);
        text-decoration: underline;
      }
    }
  }
`;

export default Footer;
